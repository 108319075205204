import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { filter, find, each, map, replace, startCase, isPlainObject, isObject, get } from 'lodash';

import { customerService, principalService } from 'common/services';
import ActionsComponent from './actions';
import { modalNames } from 'common/components/transaction-actions/modal-names';
import { hasFeaturePackage } from 'common/utilities';
import { featurePackages, featurePackageTooltips } from 'common/utilities/has-feature-package';
import sectionKeys from '../../../../routing/sections';

class RecurringActions extends Component {
	constructor(props) {
		super(props);
	}

	get actions() {
		const { row } = this.props;

		const principal = principalService.get();
		const isViewOnly = get(principal, 'isViewOnly', false);
		const noPermissions = !principal.hasAccess[sectionKeys.newTransaction];
		const hasTerminalOnly = hasFeaturePackage(featurePackages.terminalOnly);
		const disabled = hasTerminalOnly || noPermissions;
		const noPermissionsTooltip = "You don't have permissions";
		const tooltip = hasTerminalOnly
			? featurePackageTooltips.hasTerminalOnly
			: noPermissions
			? noPermissionsTooltip
			: null;

		return isViewOnly
			? []
			: [
					{
						className: 'btn btn--link datatooltip--auto datatooltip--no-wrap',
						action: () => this.openNewTransactionModal(row),
						tooltip: tooltip || 'New transaction',
						key: 'newTransaction',
						icon: 'add-plus',
						componentProps: {
							disabled,
						},
						section: sectionKeys.newTransaction,
					},
					{
						className: 'btn btn--link datatooltip--auto datatooltip--no-wrap',
						action: () => this.sendPaymentRequest(row),
						tooltip: tooltip || 'Send payment request',
						key: 'sendPaymentRequest',
						icon: 'send-email',
						componentProps: {
							disabled,
						},
						section: [sectionKeys.newTransaction],
					},
			  ];
	}

	openNewTransactionModal = async row => {
		try {
			row.showLoader(true);
			if (!row.defaultPaymentMethodId) {
				const customer = await customerService.getCustomer(row.customerId);
				row.defaultPaymentMethodId = get(customer, 'xReportData[0].defaultPaymentMethodId');
			}
			const paymentMethods = await customerService.getCustomerPaymentMethods(
				row.customerId,
				row.defaultPaymentMethodId
			);

			const defaultPayment = find(paymentMethods.xReportData, { isDefaultPaymentMethod: true });
			const mappedRow = this.prefixKeysWithX(row);
			mappedRow.xZip = defaultPayment ? defaultPayment.zip : null;
			mappedRow.xStreet = defaultPayment ? defaultPayment.street : null;
			row.openCloseModal({
				name: modalNames.newTransaction,
				data: {
					customer: mappedRow,
					paymentMethods: filter(
						map(paymentMethods.xReportData, this.prefixKeysWithX),
						payment => !!payment.xPaymentMethodId
					),
					existingTransaction: null,
					refreshGridData: row.refreshGridData,
				},
			});
		} catch (e) {
			row.handleError(e);
		}
		row.showLoader(false);
	};

	sendPaymentRequest = async row => {
		const customer = {};
		each(row, (value, key) => {
			if (!isObject(value)) {
				customer[key] = value;
			}
		});
		row.history.push({
			pathname: '/send-payment-request',
			state: { customer },
		});
	};

	prefixKeysWithX = obj => {
		let result = [];
		if (isPlainObject(obj)) {
			result = {};
			each(obj, (value, key) => {
				if (!isObject(value)) {
					result[`x${replace(startCase(key), /\s/g, '')}`] = value;
				}
			});
		} else {
			each(obj, (value, key) => {
				result.push({});
				each(value, (objValue, objKey) => {
					if (!isObject(value)) {
						result[key][`x${replace(startCase(objKey), /\s/g, '')}`] = objValue;
					}
				});
			});
		}
		return result;
	};

	render() {
		return <ActionsComponent {...this.props} dependentValues={{ actions: this.actions }} />;
	}
}

RecurringActions.propTypes = {
	row: PropTypes.object.isRequired,
};

export default RecurringActions;
