import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import {
	filter,
	map,
	cloneDeep,
	find,
	isEmpty,
	each,
	toLower,
	includes,
	get,
	some,
	slice,
	findIndex,
	concat,
	endsWith,
	every,
} from 'lodash';

import { OutsideClick, parseError, getMail, kvaasResources, compareSemver } from './../../utilities';
import { Notification } from './../../components/notifications';
import authenticationService from './../../services/authenticationService';
import principalService from './../../services/principalService';
import kvaasService from './../../services/kvaasService';
import { withCancelable } from '../cancelable';
import { withError } from '../error';
import {
	newVersion,
	phonePayFree,
	newPortal,
	oldPortal,
	updatedSettings,
	accountUpdater,
	vp3300,
	mobileAppLink,
	digitalWallet,
	giftCard,
	cardknoxSettings,
	tapToPhone,
} from './notification-types';
import { optInForNewPortal, toggleShareFeedback } from 'common/utilities/commonEnterLeaveBetaPortalMethods';
import sectionKeys from 'routing/sections';
import { hasPaymentSites, isProPay } from 'common/services/helper-service';
import UserButton from './components/UserButton';
import UserDropDown from './components/UserDropdown';
import Company from './components/Company';
import LeaveBetaFeedbackModal from '../leave-beta-feedback-modal/LeaveBetaFeedbackModal';
import { SidebarContext } from 'common/contexts';
let displayedCardknoxSettings = false;
let displayedNewVersionComponent = false;
let displayedPhonePayFree = false;
let displayedUpdatedSettings = false;
let displayedNewPortal = false;
let displayedOldPortal = false;
let displayedAccountUpdater = false;
let displayedVp3300 = false;
let displayedMobileApp = false;
let displayedDigitalWallet = false;
let displayedGiftCard = false;
let displayedTapToPhone = false;
const version = SoftwareSettings.version;

const newPortalEndpoint = ApplicationSettings.newPortalEndpoint;
const isNewPortal = newPortalEndpoint && endsWith(window.location.host, newPortalEndpoint);
const newPortalVersion = newPortalEndpoint && !isNewPortal ? version : null;
const oldPortalVersion = isNewPortal ? version : null;

const requestKeys = {
	FETCH: 'fetch',
	USERSETTINGS: 'usersettings',
	SAVE: 'save',
};

class UserAccountPanel extends Component {
	static notifications = [];
	static contextType = SidebarContext;

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			username: null,
			search: '',
			selectedCompany: null,
			availableCompanies: [],
			showCompanyDropDown: false,
			showNotificationDropDown: false,
			showNotificationDropDownDesktop: false,
			showNotificationDropDownMobile: false,
			isValidatingKey: false,
			notifications: UserAccountPanel.notifications,
			isLoadingNotifications: false,
			userSettings: null,
			principal: principalService.get(),
			isShareFeedbackOpen: false,
			switchingCompany: false,
			showUserDropDown: false,
		};

		this.toggleShareFeedback = toggleShareFeedback.bind(this);
		this.optInForNewPortal = optInForNewPortal.bind(this);
	}
	get companyAcronym() {
		const acronym = (this.state.selectedCompany.dba_alias || this.state.selectedCompany.dba)
			.split(' ')
			.map(word => word[0])
			.slice(0, 3)
			.join('')
			.toUpperCase();
		return acronym;
	}

	setNotifications = async principal => {
		if (!principal) return;
		const proPay = await isProPay();
		const hasPS = await hasPaymentSites(principal);
		this.setState({ hasPS, proPay });
	};
	async componentDidMount() {
		const user = await authenticationService.getUser();
		let state = {};
		const principal = principalService.get();
		this.setNotifications(principal);
		if (user && user.attributes && user.attributes.email) {
			state.username = user.attributes.email;
			if (principal && principal.id && principal.list) {
				const selectedItem = find(principal.list, { mid: principal.id });
				state.email = principal.email;
				state.selectedCompany = selectedItem;
				state.availableCompanies = principal.list;
			}
			this.subscription = principalService.subscribe(this.refreshKeys, true);

			state.isLoading = false;
			this.setState(state);
		}
		await Promise.all([this.loadUserSettings(), this.initializeNotifications(principal)]);
	}
	async initializeNotifications(principal) {
		if (
			displayedNewVersionComponent &&
			displayedPhonePayFree &&
			displayedNewPortal &&
			displayedOldPortal &&
			displayedUpdatedSettings &&
			displayedAccountUpdater &&
			displayedVp3300 &&
			displayedMobileApp &&
			displayedDigitalWallet &&
			displayedGiftCard &&
			displayedCardknoxSettings &&
			displayedTapToPhone
		) {
			return;
		}
		try {
			const [notifications] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.notifications),
				requestKeys.FETCH
			);

			if (get(notifications, 'data', null)) {
				if (compareSemver(notifications.data.newVersion, version) > -1) {
					displayedNewVersionComponent = true;
				}
				if (notifications.data.tapToPhone) {
					displayedTapToPhone = true;
				}
				if (notifications.data.cardknoxSettings) {
					displayedCardknoxSettings = true;
				}
				if (notifications.data.updatedSettings) {
					displayedUpdatedSettings = true;
				}
				if (notifications.data.mobileApp) {
					displayedMobileApp = true;
				}
				if (notifications.data.accountUpdater) {
					displayedAccountUpdater = true;
				}
				if (notifications.data.vp3300) {
					displayedVp3300 = true;
				}
				if (notifications.data.phonePayFree) {
					displayedPhonePayFree = true;
				}
				if (notifications.data.digital) {
					displayedDigitalWallet = true;
				}
				if (notifications.data.giftCard) {
					displayedGiftCard = true;
				}
				if (!newPortalVersion || compareSemver(notifications.data.newPortal, newPortalVersion) > -1) {
					displayedNewPortal = true;
				}
				if (!oldPortalVersion || compareSemver(notifications.data.oldPortal, oldPortalVersion) > -1) {
					displayedOldPortal = true;
				}
			}
		} catch (e) {
			const error = this.props.handleError(e, true);
			if (error) {
				//eslint-disable-next-line
				console.error(error);
			}
		}
		if (!displayedTapToPhone) {
			this.addNotification(tapToPhone);
			displayedTapToPhone = true;
		}
		if (!displayedPhonePayFree) {
			this.addNotification(phonePayFree);
			displayedPhonePayFree = true;
		}
		if (!displayedNewVersionComponent) {
			this.addNotification(newVersion);
			displayedNewVersionComponent = true;
		}

		if (!displayedNewPortal) {
			this.addNotification({
				...newPortal,
				componentProps: {
					optInForNewPortal: this.optInForNewPortal,
				},
			});
			displayedNewPortal = true;
		}
		if (!displayedOldPortal) {
			this.addNotification({
				...oldPortal,
				componentProps: {
					optInForNewPortal: this.optInForNewPortal,
				},
			});
			displayedOldPortal = true;
		}
		if (!displayedUpdatedSettings) {
			this.addNotification(updatedSettings);
			displayedUpdatedSettings = true;
		}
		if (!displayedMobileApp) {
			this.addNotification(mobileAppLink);
			displayedMobileApp = true;
		}

		if (principal && principal.hasAccess && principal.hasAccess[sectionKeys.featureAddons]) {
			if (!displayedAccountUpdater) {
				this.addNotification(accountUpdater);
				displayedAccountUpdater = true;
			}

			if (!displayedVp3300) {
				this.addNotification(vp3300);
				displayedVp3300 = true;
			}
			if (!displayedDigitalWallet && get(principal, 'idInfo.xSupportsDigitalWallet', false)) {
				this.addNotification(digitalWallet);
				displayedDigitalWallet = true;
			}
			if (!displayedGiftCard) {
				this.addNotification(giftCard);
				displayedGiftCard = true;
			}
			if (!displayedCardknoxSettings) {
				this.addNotification(cardknoxSettings);
				displayedCardknoxSettings = true;
			}
		}
	}
	async loadUserSettings() {
		try {
			const [userSettings] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.userSettings),
				requestKeys.USERSETTINGS
			);
			if (userSettings && userSettings.data) {
				this.setState({ userSettings });
			}
		} catch (e) {
			const error = this.props.handleError(e, true);
			if (error) {
				//eslint-disable-next-line
				console.error(error);
			}
		}
	}

	componentWillUnmount = () => {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	};

	refreshKeys = principal => {
		const newState = {};
		if (principal && principal.id && principal.list) {
			const selectedItem = filter(principal.list, { mid: principal.id })[0];
			newState.selectedCompany = selectedItem;
			newState.availableCompanies = principal.list;
		}
		this.setState(newState);
	};
	closeCompanyDropdown = () => {
		this.setState({
			showCompanyDropDown: false,
			showUserDropDown: false,
		});
	};
	toggleCompanyDropDown = () => {
		const { showCompanyDropDown } = this.state;
		this.setState({
			showCompanyDropDown: !showCompanyDropDown,
		});
	};

	toggleUserDropDown = () => {
		const { showUserDropDown } = this.state;
		this.setState({
			showUserDropDown: !showUserDropDown,
		});
	};

	toggleNotificationDropDown = (event, ignoreMap = false) => {
		const { showNotificationDropDown, notifications } = this.state;
		if (!showNotificationDropDown && notifications.length === 0) {
			return;
		}
		if (!showNotificationDropDown && window.innerWidth < 760) {
			this.context.closeSidebar();
		}
		this.setState(
			{
				showNotificationDropDown: !showNotificationDropDown,
				showNotificationDropDownDesktop: !showNotificationDropDown,
				showNotificationDropDownMobile: !showNotificationDropDown,
				notifications: ignoreMap
					? notifications
					: map(notifications, notification => ({
							...notification,
							isOpen: showNotificationDropDown ? false : notifications.length === 1 && !notification.read,
							read: (!showNotificationDropDown && notifications.length === 1) || notification.read,
							selected: false,
					  })),
			},
			this.syncNotifications
		);
	};

	onCloseNotificationDropDown = () => {
		if (!this.state.showNotificationDropDown) {
			return;
		}
		this.setState({
			showNotificationDropDown: false,
		});
	};

	onCloseNotificationDropDownDesktop = () => {
		if (!this.state.showNotificationDropDownDesktop) {
			return;
		}
		this.setState(
			{
				showNotificationDropDownDesktop: false,
			},
			this.onCloseNotificationDropDown
			//may need to add this back when mobile view is implemented
			//() => {
			//if (!this.state.showNotificationDropDownMobile) {
			//this.onCloseNotificationDropDown();
			//}
			//}
		);
	};

	onCloseNotificationDropDownMobile = () => {
		if (!this.state.showNotificationDropDownMobile) {
			return;
		}
		this.setState(
			{
				showNotificationDropDownMobile: false,
			},
			this.onCloseNotificationDropDown
			//may need to add this back when mobile view is implemented
			//() => {
			//if (!this.state.showNotificationDropDownDesktop) {
			//this.onCloseNotificationDropDown();
			//}
			//}
		);
	};
	addNotification = notification => {
		this.setState(prevState => {
			const newNotification = {
				isOpen: false,
				read: false,
				selected: false,
				...notification,
			};
			return { notifications: [newNotification, ...prevState.notifications] };
		}, this.syncNotifications);
	};

	syncNotifications = () => {
		UserAccountPanel.notifications = this.state.notifications;
	};

	renderCompanySubmenu = numberOfCompanies => {
		const { selectedCompany, availableCompanies, search } = this.state;
		let filteredCompanies = cloneDeep(availableCompanies);

		if (!isEmpty(search)) {
			const lowerCasedSearch = toLower(search);
			filteredCompanies = this.filterCompaniesBySearch(filteredCompanies, lowerCasedSearch);
		}

		const selectedCompanyIndex = findIndex(filteredCompanies, ['mid', get(selectedCompany, 'mid', '')]);
		if (selectedCompanyIndex > -1) {
			filteredCompanies = this.reorderCompanies(filteredCompanies, selectedCompanyIndex);
		}

		if (numberOfCompanies) {
			filteredCompanies = slice(filteredCompanies, 0, numberOfCompanies);
		}

		return filteredCompanies.length ? this.renderCompanies(filteredCompanies) : this.renderNoResults();
	};

	filterCompaniesBySearch = (companies, search) => {
		return filter(companies, item => {
			const combinedString = toLower(`${item.dba_alias || item.dba} - ${item.mid}`);
			return includes(combinedString, search);
		});
	};

	reorderCompanies = (companies, selectedIndex) => {
		return concat(
			slice(companies, selectedIndex, selectedIndex + 1),
			slice(companies, 0, selectedIndex),
			slice(companies, selectedIndex + 1)
		);
	};

	renderCompanies = companies => {
		return map(companies, company => (
			<Company
				key={company.mid}
				company={company}
				selectedCompany={this.state.selectedCompany}
				switchCompany={this.switchCompany}
			/>
		));
	};

	renderNoResults = () => {
		return (
			<li key="--empty--" className="header__submenu__list__item">
				No results
			</li>
		);
	};
	showInactiveUserNotification = e =>
		this.notification.addNotification({
			message: 'The account is inactive for user',
			success: false,
			ref: e && e.ref,
		});

	switchCompany = company => {
		localStorage.removeItem('hasPaymentSites');
		localStorage.removeItem('isProPay');

		this.setState({ switchingCompany: true }, async () => {
			const { availableCompanies, isValidatingKey } = this.state;

			if (isValidatingKey) {
				return;
			}

			try {
				this.setState({ isValidatingKey: true });
				const idInfo = await authenticationService.validateKey(company.key, company.mid);
				this.setState(
					{
						selectedCompany: company,
						search: '',
						switchingCompany: true,
					},
					() => {
						(() => {
							principalService.set({
								id: company.mid,
								companyName: company.dba_alias || company.dba,
								list: availableCompanies,
								idInfo: idInfo,
							});
						})(),
							(() => {
								window.location.reload();
							})();
					}
				);
			} catch (e) {
				if (!e || !e.isCanceled) {
					const { message, stack } = parseError(e);
					if (includes(toLower(message), ['inactive'])) {
						this.showInactiveUserNotification(e);
					} else {
						this.setState({ isValidatingKey: false });
						this.notification.addNotification({
							message:
								!e.isApiError && stack
									? getMail(stack)
									: `An error has occurred. Please try again (${
											includes(
												[
													'networkerror when attempting to fetch resource.',
													'network request failed',
													'failed to fetch',
												],
												toLower(message)
											)
												? 'FFE'
												: message
									  })`,
							ref: e && e.ref,
							success: false,
						});
					}
				}
			} finally {
				this.setState({ switchingCompany: false, isValidatingKey: false });
			}
		});
	};

	searchCompany = e => {
		this.setState({
			search: e.target.value,
		});
	};

	handleNotificationSelect = index => {
		const notifications = [...this.state.notifications];
		notifications[index] = {
			...notifications[index],
			selected: !notifications[index].selected,
		};
		this.setState({ notifications }, this.syncNotifications);
	};

	handleAllSelect = selected => {
		const notifications = map(this.state.notifications, notification => ({
			...notification,
			selected,
		}));
		this.setState({ notifications }, this.syncNotifications);
	};

	toggleNotificationOpen = index => {
		const notifications = [...this.state.notifications];
		notifications[index] = {
			...notifications[index],
			isOpen: !notifications[index].isOpen,
			read: true,
		};
		this.setState({ notifications }, this.syncNotifications);
	};

	handleNotificationDelete = async () => {
		const { notifications, isLoadingNotifications } = this.state;
		const { makePendingRequest, handleError } = this.props;

		if (isLoadingNotifications) {
			return;
		}

		const toDelete =
			notifications.length === 1 && notifications[0].isOpen ? [notifications[0]] : filter(notifications, 'selected');

		if (isEmpty(toDelete)) {
			return;
		}

		try {
			this.setState({ isLoadingNotifications: true });

			const [oldData] = await makePendingRequest(kvaasService.get(kvaasResources.notifications), requestKeys.SAVE);
			const data = oldData && oldData.data ? { ...oldData.data } : {};

			each(toDelete, ({ key, value }) => {
				data[key] = value;
			});

			const request = {
				newData: {
					revision: 0,
					data,
				},
				oldData,
				...kvaasResources.notifications,
			};

			await makePendingRequest(kvaasService.save(request), requestKeys.SAVE);

			const updatedNotifications = notifications.filter(notification => !some(toDelete, { key: notification.key }));

			const newState = { notifications: updatedNotifications, isLoadingNotifications: false };

			if (isEmpty(updatedNotifications)) {
				newState.showNotificationDropDown = false;
			}

			this.setState(newState, this.syncNotifications);
		} catch (e) {
			if (handleError(e)) {
				this.setState({ isLoadingNotifications: false });
			}
		}
	};
	getNotifications = () => {
		const { principal, hasPS, proPay } = this.state;
		let availableNotifications = cloneDeep(this.state.notifications);
		if (!principal.hasAccess[sectionKeys.sendPaymentRequest] || displayedDigitalWallet || !hasPS || !proPay) {
			availableNotifications = filter(availableNotifications, notification => notification.key !== 'digitalWallet');
		}
		return availableNotifications;
	};

	renderNotificationMenu(isMobile) {
		const { showNotificationDropDown, isLoadingNotifications } = this.state;
		const notificationButtonClass = showNotificationDropDown ? 'is-active' : '';
		const notificationDropDownActiveClass = showNotificationDropDown ? 'is-active' : '';
		const onClose = isMobile ? this.onCloseNotificationDropDownMobile : this.onCloseNotificationDropDownDesktop;
		const notifications = this.getNotifications();
		let unreadNotifications = filter(notifications, ({ read }) => !read).length;
		const allSelected = every(notifications, ({ selected }) => selected);

		return (
			<OutsideClick className="header__notification" action={onClose}>
				<button
					className={`header__notification__button datatooltip--down-left ${notificationButtonClass}`}
					data-tooltip={isEmpty(notifications) && !this.state.showNotificationDropDown ? 'No new notifications.' : null}
					onClick={this.toggleNotificationDropDown}
				>
					<i className="icon icon--sml icon--bell"></i>
					{unreadNotifications > 0 && <span className="header__notification__new"></span>}
				</button>
				{notifications.length > 0 && this.state.showNotificationDropDown && (
					<div className={`header__dropdown header__dropdown--notification ${notificationDropDownActiveClass}`}>
						<div className="header__dropdown--notification__header">
							<h6 className="type--base type--wgt--medium">Notifications</h6>
							{!isLoadingNotifications && (
								<div className="flex--primary">
									{notifications.length > 1 && (
										<a
											onClick={() => this.handleAllSelect(!allSelected)}
											href="javascript:void(0)"
											className="btn btn--sml btn--link spc--right--lrg"
										>
											{allSelected ? 'Unselect' : 'Select'} All
										</a>
									)}
									<button
										data-tooltip="Clear"
										className="btn btn--link datatooltip--auto datatooltip--down-left"
										type="button"
										onClick={this.handleNotificationDelete}
									>
										<i className="icon icon--sml icon--delete"></i>
									</button>
								</div>
							)}
						</div>
						<div></div>

						{isLoadingNotifications && <div className="loader__spinner" />}

						<div>
							{!isLoadingNotifications &&
								map(
									notifications,
									({ Component, key, read, selected, isOpen, type, title, summary, componentProps }, index) => (
										<div key={key} className={`header__notification__item ${!read ? 'is-read' : ''}`}>
											{isOpen ? (
												<div className="flex--tertiary flex--top flex--gap--sml flex--nowrap type--p3">
													<Component isMobile={isMobile} {...componentProps} />
													<button className="btn btn--link">
														<i
															className="icon icon--tny icon--close"
															onClick={() => this.toggleNotificationOpen(index)}
														></i>
													</button>
												</div>
											) : (
												<React.Fragment>
													<div className="header__notification__checkbox">
														<input
															className="input--check input--check--no-label input--check--rounded"
															checked={selected}
															type="checkbox"
															name="select"
															id={`select-${key}`}
															onChange={() => this.handleNotificationSelect(index)}
														/>
														<label htmlFor={`select-${key}`}></label>
													</div>
													<div className={`header__notification__icon ${type}`}></div>
													<div className="fullwidth">
														<p className="header__notification__item__title">{title}</p>
														<p className="type--xsml">{summary}</p>
													</div>
													<button className="btn btn--med btn--link" onClick={() => this.toggleNotificationOpen(index)}>
														<i className="icon icon--sml icon--chevron--right--light"></i>
													</button>
												</React.Fragment>
											)}
										</div>
									)
								)}
						</div>
					</div>
				)}
			</OutsideClick>
		);
	}

	renderUserMenu = isMobile => {
		const { email, showUserDropDown, selectedCompany } = this.state;
		const { displayEnterBetaPortal } = this.props;
		const userButtonClass = showUserDropDown ? 'open' : 'closed';
		const userDropDownActiveClass = showUserDropDown ? 'open' : 'closed';

		return (
			<OutsideClick action={this.closeCompanyDropdown} className="header__account__wrapper">
				<UserButton
					isMobile={isMobile}
					companyAcronym={this.companyAcronym}
					selectedCompany={selectedCompany}
					email={email}
					toggleUserDropDown={this.toggleUserDropDown}
					userButtonClass={userButtonClass}
				/>
				<UserDropDown
					search={this.state.search}
					searchCompany={this.searchCompany}
					availableCompanies={this.state.availableCompanies}
					showUserDropDown={showUserDropDown}
					showCompanyDropDown={this.state.showCompanyDropDown}
					renderCompanySubmenu={this.renderCompanySubmenu}
					displayEnterBetaPortal={displayEnterBetaPortal}
					toggleShareFeedback={this.toggleShareFeedback}
					toggleCompanyDropDown={this.toggleCompanyDropDown}
					isNewPortal={isNewPortal}
					userDropDownActiveClass={userDropDownActiveClass}
				/>
			</OutsideClick>
		);
	};

	render() {
		const { isLoading, principal, isShareFeedbackOpen, switchingCompany } = this.state;
		const mid = get(principal, 'idInfo.xMerchantID');
		const email = get(principal, 'email');

		return (
			<React.Fragment>
				{isLoading ? null : (
					<React.Fragment>
						{switchingCompany && <div className="loader--progress"></div>}

						{this.renderNotificationMenu(false)}
						{this.renderUserMenu(false)}
					</React.Fragment>
				)}
				<LeaveBetaFeedbackModal mid={mid} email={email} isOpen={isShareFeedbackOpen} onClose={this.optInForNewPortal} />
				<Notification ref={el => (this.notification = el)} />
			</React.Fragment>
		);
	}
}

UserAccountPanel.propTypes = {
	handleError: func,
	makePendingRequest: func,
	displayEnterBetaPortal: bool,
};

export default withError(withCancelable(UserAccountPanel));
