import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MainFilter } from '../filter';
import Tabs from 'common/components/grid/Tabs';
import batchTabs from '../batchTabs';
import { every } from 'lodash';

function mapBatchTab(tab, key) {
	return {
		path: key,
		title: tab,
		mobileTitle: tab,
		linkTitle: tab,
	};
}

const routes = [mapBatchTab(batchTabs.closed, 'closed'), mapBatchTab(batchTabs.open, 'open')];

class BatchGrid extends Component {
	render() {
		const {
			caption,
			children,
			handleBreakdownOpen,
			exportButtons,
			filters,
			activeFilters,
			onFilterUpdate,
			toggleSummary,
			activeTab,
			switchTab,
			isLoading,
			expanded,
			toggleExpandAll,
		} = this.props;
		const allExpanded = every(expanded, row => row);

		return (
			<React.Fragment>
				<div className="batches__table__header">
					<div className="flex--tertiary flex--gap--sml--alt spc--bottom--med">
						<h3>{caption}</h3>
						<div className="flex--primary flex--gap--sml--alt">
							<div className="flex--primary">{exportButtons}</div>
							<button className="btn btn--med btn--primary" onClick={handleBreakdownOpen} disabled={isLoading}>
								Breakdown by Card
							</button>
						</div>
					</div>

					<Tabs tabs={routes} useRoutes={false} activeTab={activeTab} onTabChange={switchTab} />
					<div className="flex--tertiary spc--bottom--med">
						<MainFilter filters={filters} activeFilters={activeFilters} onFiltersUpdate={onFilterUpdate} />
						<div className="flex--primary flex--gap--sml spc--bottom--sml">
							<button
								className="btn btn--sml btn--action--secondary"
								onClick={() => toggleExpandAll('all', allExpanded)}
								disabled={isLoading}
							>
								{allExpanded ? 'Collapse All' : 'Expand All'}
							</button>
							<button className="btn btn--sml btn--action--secondary" onClick={toggleSummary} disabled={isLoading}>
								Show Summary
							</button>
						</div>
					</div>
				</div>
				{isLoading ? null : (
					<div className="table__wrapper">
						<table className="table table--primary">{children}</table>
					</div>
				)}
			</React.Fragment>
		);
	}
}

BatchGrid.propTypes = {
	caption: PropTypes.string,
	children: PropTypes.any,
	handleBreakdownOpen: PropTypes.func,
	toggleSummary: PropTypes.func.isRequired,
	toggleExpandAll: PropTypes.func.isRequired,
	exportButtons: PropTypes.any,
	filters: PropTypes.array.isRequired,
	activeFilters: PropTypes.array.isRequired,
	onFilterUpdate: PropTypes.func.isRequired,
	activeTab: PropTypes.string.isRequired,
	switchTab: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	expanded: PropTypes.object.isRequired,
};

export default BatchGrid;
