import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageWithFallback from 'common/components/ImageFallback';

class PublicLayoutComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Fragment>
				<div className={`auth ${this.props.customLayoutClass ? this.props.customLayoutClass : ''}`}>
					<aside className="auth__sidebar">
						<ImageWithFallback imgAttributes={{ alt: 'logo' }}>
							{props => (
								<img alt="logo" src={'static/media/sola-logo.svg'} className="auth__sidebar__logo" {...props} />
							)}
						</ImageWithFallback>
					</aside>

					<div className="auth__main">
						<div className="dashboard__message">
							<p className="type--p1">
								<span className="type--p1--medium">Cardknox is now Sola:</span> Discover the journey that brought us
								here
							</p>
							<a
								className="btn btn--med btn--secondary"
								href="https://solapayments.com/launch-page/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<span>Learn more</span>
							</a>
						</div>
						{this.props.children}
					</div>
				</div>
			</Fragment>
		);
	}
}

PublicLayoutComponent.propTypes = {
	children: PropTypes.any,
	customLayoutClass: PropTypes.string,
};

export default PublicLayoutComponent;
