import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import AccountMenu from './AccountMenu';
const newPortalEndpoint = ApplicationSettings.newPortalEndpoint;

const UserDropDown = ({
	showUserDropDown,
	userDropDownActiveClass,
	showCompanyDropDown,
	renderCompanySubmenu,
	search,
	availableCompanies,
	toggleCompanyDropDown,
	searchCompany,
	displayEnterBetaPortal,
	toggleShareFeedback,
	isNewPortal,
}) => {
	const renderAccountSection = () => (
		<div className="header__dropdown--account__section">
			{!showCompanyDropDown && <div className="header__dropdown--account__title">Switch Account</div>}
			<ul className="account-list">
				{!showCompanyDropDown && renderCompanySubmenu(3)}
				<AccountMenu
					search={search}
					showCompanyDropDown={showCompanyDropDown}
					availableCompanies={availableCompanies}
					toggleCompanyDropDown={toggleCompanyDropDown}
					searchCompany={searchCompany}
					renderCompanySubmenu={renderCompanySubmenu}
				/>
			</ul>
		</div>
	);

	const renderSettingsSection = () => (
		<div className="header__dropdown--account__section">
			<div className="header__dropdown--account__title">Settings</div>
			<ul>
				{newPortalEndpoint && (
					<li className={`${displayEnterBetaPortal ? ' show--to--sml--block' : ''}`}>
						<button onClick={() => toggleShareFeedback(isNewPortal)}>
							<i className={`icon icon--med icon--${isNewPortal ? 'leave' : 'enter'}`}></i>
							{isNewPortal ? 'Leave Beta Portal' : 'Enter Beta Portal'}
						</button>
					</li>
				)}
				<li>
					<NavLink to="/settings/user-settings/account-dropdown-order">
						<i className="icon icon--med icon--settings"></i>
						<span className="type--color--text">Display Settings</span>
					</NavLink>
				</li>
			</ul>
		</div>
	);

	const renderLogoutSection = () => (
		<div className="header__dropdown--account__section">
			<ul>
				<li>
					<NavLink to="/logout" className="logout">
						Log out
					</NavLink>
				</li>
			</ul>
		</div>
	);

	if (!showUserDropDown) return null;

	return (
		<div className={`header__dropdown header__dropdown--account ${userDropDownActiveClass}`}>
			{renderAccountSection()}
			{!showCompanyDropDown && (
				<Fragment>
					{renderSettingsSection()}
					{renderLogoutSection()}
				</Fragment>
			)}
		</div>
	);
};

UserDropDown.propTypes = {
	showUserDropDown: PropTypes.bool.isRequired,
	userDropDownActiveClass: PropTypes.string.isRequired,
	showCompanyDropDown: PropTypes.bool.isRequired,
	renderCompanySubmenu: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
	availableCompanies: PropTypes.array.isRequired,
	toggleCompanyDropDown: PropTypes.func.isRequired,
	searchCompany: PropTypes.func.isRequired,
	displayEnterBetaPortal: PropTypes.bool,
	toggleShareFeedback: PropTypes.func.isRequired,
	isNewPortal: PropTypes.bool.isRequired,
};

export default UserDropDown;
