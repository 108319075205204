import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withStepup } from 'common/components/stepup-mfa';
import WebhookSettings from './webhookSettings/WebhookSettings';
import FraudSettings from './FraudSettings';
import KeyManagementComponent from './KeyManagement';
import PaymentMethodsComponent from './PaymentMethods';
import SettingsTemplate from './SettingsTemplate';
import PaymentSiteComponent from './PaymentSite';
import sectionKeys from 'routing/sections';
import { paymentEngineSettingsService, principalService } from 'common/services';
import { get, includes } from 'lodash';
import { hasPaymentSites } from 'common/services/helper-service';
import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import PaymentEngine from './PaymentEngine';
const webhookRouteUrl = 'webhook';

const redirectComponents = {
	user: () => <Redirect to="/settings/user-settings" />,
	account: () => <Redirect to="/settings/account-settings" />,
	sola: () => <Redirect to="/settings/sola-account-settings" />,
};

class SolaAccountSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			principal: principalService.get(),
			userRole: get(principalService.get(), 'idInfo.permissions.role'),
		};
	}
	componentDidMount = async () => {
		try {
			const hasPS = await hasPaymentSites(this.state.principal);
			this.setState({ hasPaymentSite: hasPS });
			await this.props.makePendingRequest(paymentEngineSettingsService.list());
		} catch (e) {
			this.setState({
				hidePaymentEngine: true,
			});
		}
		this.subscription = principalService.subscribe(newPrincipal => {
			this.setState({
				principal: newPrincipal,
				userRole: get(newPrincipal, 'idInfo.permissions.role'),
			});
		});
	};

	componentWillUnmount() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	get isFraud() {
		return get(this.state.principal, `hasAccess.${sectionKeys.fraud}`);
	}
	get routes() {
		const { userRole } = this.state;
		const routes = [
			{
				path: '/user-settings',
				component: redirectComponents.user,
				label: 'User Settings',
				isMain: true,
				tooltip: 'Individual user preferences and options'
			},
			{
				path: '/account-settings',
				component: redirectComponents.account,
				label: 'Portal Settings',
				hidden: this.isFraud,
				isMain: true,
				tooltip: 'Settings that apply across the portal and impact all users',
			},
			{
				path: '/sola-account-settings',
				component: redirectComponents.sola,
				label: 'Gateway Settings',
				hidden: this.isFraud,
				isMain: true,
				tooltip: 'Global gateway account settings that function independently of the portal',
				disabled: true,
			},
			{
				path: '/payment-site',
				component: PaymentSiteComponent,
				label: 'PaymentSITE',
				hidden: () => {
					return !includes(['admin', 'advanced'], userRole) || this.isFraud;
				},
				section: sectionKeys.newTransaction,
			},
			{
				path: '/key-management',
				component: KeyManagementComponent,
				label: 'Key Management',
				hidden: this.isFraud,
				section: sectionKeys.users,
				isDefault: true,
			},
			{
				path: `/fraud`,
				component: FraudSettings,
				label: 'Fraud Settings',
			},
			{
				path: `/${webhookRouteUrl}`,
				component: WebhookSettings,
				label: 'Webhook Settings',
			},
			{
				path: '/payment-methods',
				component: PaymentMethodsComponent,
				label: 'Payment Methods',
				hidden: () => !includes(['admin', 'advanced'], userRole) || this.isFraud,
				section: sectionKeys.dashboard,
			},
			{
				path: '/payment-engine',
				component: PaymentEngine,
				label: 'Payment Engine',
				section: sectionKeys.paymentEngine,
				hidden: () => !includes(['admin'], userRole),
			},
		];

		return this.getAvailableRoutes(routes);
	}

	getAvailableRoutes = routes => {
		const { hidePaymentEngine, hasPaymentSite } = this.state;
		let aRoutes = routes;
		if (!hasPaymentSite) {
			aRoutes = aRoutes.filter(r => r.path !== '/payment-site');
		}
		if (hidePaymentEngine) {
			aRoutes = aRoutes.filter(r => r.path !== '/payment-engine');
		}
		return aRoutes;
	};
	getDefaultRouteUrl() {
		const { userRole, hasPaymentSite } = this.state;
		if (this.isFraud) {
			return 'fraud';
		} else if (hasPaymentSite && includes(['admin', 'advanced'], userRole)) {
			return 'payment-site';
		} else if (includes(['admin', 'advanced'], userRole)) {
			return 'key-management';
		} else return webhookRouteUrl;
	}
	render() {
		return (
			<SettingsTemplate
				title={'Sola Account Settings'}
				routes={this.routes}
				defaultRouteUrl={this.getDefaultRouteUrl()}
				defaultRoute={this.routes[0]}
				redirectToRoute={this.getDefaultRouteUrl()}
				{...this.props}
			/>
		);
	}
}
SolaAccountSettings.propTypes = {
	makePendingRequest: PropTypes.func.isRequired,
};
export default withError(withCancelable(withStepup(SolaAccountSettings, 'portal-cardknox')));
