import React, { Component, Fragment, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
	trim,
	clone,
	toLower,
	split,
	includes,
	get,
	each,
	every,
	transform,
	startsWith,
	noop,
	isArray,
	isEmpty,
	map,
	compact,
	toNumber,
	head,
	cloneDeep,
	toString,
} from 'lodash';
import NumberFormat from 'react-number-format';
import { ExportToCsv } from 'export-to-csv';

import { ReactToPrint } from '../../../Common/components/react-to-print';
import {
	transactionService,
	principalService,
	kvaasService,
	logoManagementService,
	authenticationService,
} from '../../../Common/services';
import {
	PrintTransactionReceiptComponent,
	PrinterReceipt,
	modalNames,
} from '../../../Common/components/transaction-actions';
import {
	CurrencyMap,
	renderIf,
	PaymentTransactionTypes,
	checkIfCanadian,
	kvaasResources,
	OutsideClick,
} from '../../../Common/utilities';
import { Notification } from '../../../Common/components/notifications';
import { StatusComponent, StatusFraudComponent } from './../../../Common/components/columns/formatters';
import { exportOptions } from './../../../Common/components/export/export-options';
import { generateFileName } from './../../../Common/components/export/helpers';
import { withError } from './../../../Common/components/error';
import { withCancelable } from './../../../Common/components/cancelable';
import { validators } from '../../../Common/fields';
import { mapAvsItem } from 'common/utilities';
import { withForwardRef } from '../../../Common/components/with-forward-ref';
import { Modal } from '../../../Common/components/modal';
import TransactionReceipt from '../../../Common/components/transaction-actions/receipt';
import { hasFeaturePackage, featurePackages, featurePackageTooltips } from 'common/utilities/has-feature-package';
import { withLoader } from 'common/components/loader';
import sectionKeys from 'routing/sections';
import renderCustomFields from 'common/components/transaction-actions/printUtils/printUtils';
import { exportService } from 'common/components/export/exportService';
import LinkTransactionComponent from 'common/components/transaction-actions/LinkTransaction';
import RenderLinkPaymentMethodOrCustomer from '../components/RenderLinkPaymentCustomer';
import { SidebarContext } from 'common/contexts';
import {
	hasCustomerBilling,
	isApproved,
	isErrored,
	isErroredToken,
	isNotAllowedPaymentMethod,
	shouldDisableAddCustomer,
} from './utils/utils';

const requestKeys = {
	LABELS: 'labels',
	DATA: 'data',
	LOGO: 'logo',
	FETCH: 'fetch',
};

const hardcodedKeys = [
	'xZip',
	'xToken',
	'xCustom',
	'xCustId',
	'xCustomerId',
	'xResponseResult',
	'xSerialNumber',
	'xStreet',
	'xServiceFee',
	'xTax',
	'xInvoice',
	'xOrderID',
	'xEnteredDate',
	'xBillCompany',
	'xShipCompany',
	'xBillPhone',
	'xShipPhone',
	'xBillStreet',
	'xShipStreet',
	'xEmail',
	'xShipEmail',
	'xBillsState',
	'xBillFirstName',
	'xBillLastName',
	'xShipFirstName',
	'xShipLastName',
	'xBillCity',
	'xBillZip',
	'xShipCity',
	'xShipState',
	'xShipZip',
	'xProcessingFee',
	'xRequestAmount',
	'xCommand',
	'xSplitAmount',
	'xIssuingBank',
	'xIssuingBankPhone',
	'xIssuingBankWebsite',
	'xResponseAVSCode',
	'xIsSplitCapturable',
	'xClearedAmount',
	'xClearedCount',
	'xDescription',
	'xMaskedCardNumber',
	'xMaskedAccountNumber',
	'xRefNum',
	'xSignature',
	'xAmount',
	'xCryptoStatus',
	'xCryptoProcessingFee',
	'xCryptoTransactionFee',
	'xCryptoNetworkFee',
	'xAchReturnFee',
];
const initialEmailPopupState = {
	showShareEmail: false,
	shareEmail: '',
	shareEmailValid: false,
	shareEmailSubmitted: false,
	sendCopy: false,
};

class RowDetailsComponent extends Component {
	constructor(props) {
		super(props);
		this.principal = principalService.get();
		this.state = clone(this.initialState);
		this.emailRef = createRef();
		this.notificationRef = createRef();
		this.setState = this.setState.bind(this);
	}
	static contextType = SidebarContext;

	async componentDidMount() {
		if (!this.props.inPopup) {
			this.context.toggleSidebarExpandedClass(true);
		}
		await this.loadData();
		this.loadLabels();
	}

	componentWillUnmount() {
		this.context.toggleSidebarExpandedClass(false);
	}

	componentDidUpdate() {
		if (this.emailRef.current) {
			const isOverflowing = this.emailRef.current.offsetWidth - 1 !== this.emailRef.current.scrollWidth;
			if (isOverflowing !== this.state.isOverflowing) {
				this.setState({ isOverflowing });
			}
		}
	}
	get hideFooter() {
		return this.props.hideFooter || this.isTransactionDeclined || (this.hideLinkPaymentButtons && this.state.isErrored);
	}

	get hideLinkPaymentButtons() {
		const { disableAddCustomer, isApproved, isErroredToken } = this.state;
		return !disableAddCustomer && !isApproved && !isErroredToken;
	}
	get zipLabel() {
		return this.state.isCanadian ? 'Postal Code' : 'Zip';
	}

	get stateLabel() {
		return this.state.isCanadian ? 'Province' : 'State';
	}

	get isAuthOnly() {
		const { data } = this.state;
		return toLower(data.xCommand).indexOf('authonly') !== -1;
	}
	get isTransactionDeclined() {
		const { data } = this.state;
		return startsWith(toLower(data.xResponseError), 'declined');
	}
	get initialState() {
		return {
			...initialEmailPopupState,
			data: null,
			logoUrlBase64: null,
			isLoading: true,
			emailCustomer: false,
			username: null,
			isCanadian: false,
			permissions: {},
			isOverflowing: false,
			ishiddenLinkTransaction: false,
			isOpenLinkExistingCustomer: false,
			selectedCustomer: null,
			emailNotificationVisible: false,
			setNewCardAsDefault: false,
			printerReceiptOption: false,
			expanded: {
				general: true,
				bill: true,
				ship: true,
				customFields: true,
				authorization: true,
				additionalInformation: false,
				processingDetails: false,
				issuingBankInfo: false,
			},
			isApproved: false,
			isErroredToken: false,
			shouldDisableAddCustomer: false,
			hasCustomerBilling: false,
			isNotAllowedPaymentMethod: false,
		};
	}

	get hasTerminalOnly() {
		return hasFeaturePackage(featurePackages.terminalOnly);
	}

	get allExpanded() {
		return every(this.state.expanded, isExpanded => isExpanded);
	}

	getShouldDisplayAuthoriazion = (userSettings, data) => {
		return (
			get(userSettings, 'data.displayAuthorization', false) &&
			get(data, 'xRequestAmount', false) &&
			split(toLower(data.xCommand), ':')[1] === 'capture'
		);
	};

	closeRow = e => {
		this.context.toggleSidebarExpandedClass(false);
		this.props.closeRow(e);
	};

	closeSplitCapturedAuthOnly = async () => {
		const { row, makePendingRequest, handleError } = this.props;
		try {
			const addNotification = get(this.notificationRef, 'current.addNotification', noop);
			const { xRefNum: ref, xStatus } = await makePendingRequest(
				transactionService.closeSplitCapturedAuthOnly(row.xRefNum, row.xMerchantId),
				requestKeys.DATA
			);
			addNotification({
				message: 'Closed Split Capture successfully',
				success: toLower(xStatus) === 'approved',
				onClose: this.refreshGridData,
				ref,
			});
		} catch (e) {
			handleError(e);
		}
	};

	toggleExpand = name => {
		const { expanded } = this.state;
		const newState = { expanded: { ...expanded } };

		if (name === 'all') {
			each(expanded, (_, key) => {
				newState.expanded[key] = !this.allExpanded;
			});
		} else {
			newState.expanded[name] = !expanded[name];
		}

		this.setState(newState);
	};

	isProPay = (data = this.state.data) => data.xProcessingFee > 0;

	// This will need to be refactor at a later date as it's not used within this component but within RenderLinkPaymentCustomer
	openLinkTransactionPopup = async linkPaymentMethod => {
		const {
			refreshGridData,
			openModal,
			showLoader,
			makePendingRequest,
			handleError,
			row: { xMerchantId },
		} = this.props;

		this.openModal(
			{
				component: LinkTransactionComponent,
				props: {
					shouldCloseOnOverlayClick: false,
				},
			},
			{
				row: this.state.data,
				refreshGridData,
				openModal,
				showLoader,
				makePendingRequest,
				handleError,
				xMerchantId,
				linkPaymentMethod: linkPaymentMethod,
				newCustomer: !linkPaymentMethod, // for better readability
			}
		);
	};

	setQueryParamAndRefresh = (param, value) => {
		const history = this.props.history;
		let searchParams = new URLSearchParams(window.location.search);

		searchParams.set(param, value);
		history.push({ search: toString(searchParams) });
		window.location.reload();
	};

	loadLabels = () => {
		const isCanadian = checkIfCanadian();

		this.setState({
			isCanadian,
		});
	};

	loadData = async () => {
		try {
			const principal = principalService.get();
			this.setState({
				permissions: get(principal, 'idInfo.permissions', {}),
			});
			const { row } = this.props;
			const refnum = row.xCommand == 'CC:Capture' && row.xRefnumCurrent ? row.xRefnumCurrent : row.xRefNum;
			const [
				{ data, related, ...status },
				user,
				[customDisplayLabels, convenienceFees, recurringSchedules, logoManagement, portalFlags, userSettings],
			] = await this.props.makePendingRequest(
				Promise.all([
					transactionService.getTransactionExtended(refnum, row.xMerchantId),
					authenticationService.getUser(),
					kvaasService.get(
						kvaasResources.transactionDisplayLabels,
						kvaasResources.convenienceFees,
						kvaasResources.recurringSchedules,
						kvaasResources.logoManagement,
						kvaasResources.portalFlags,
						kvaasResources.userSettings
					),
				]),
				requestKeys.DATA
			);
			const setNewCardAsDefault = get(recurringSchedules, 'data.setNewCardAsDefault', false);
			const convenienceCustomKey = get(convenienceFees, 'data.convenienceCustomKey', '');
			const originalCustomKey = get(convenienceFees, 'data.originalCustomKey', '');
			const showIssuingBankInfo = get(portalFlags, 'data.showIssuingBankInfo', false);
			const splitCaptureEnabled = get(portalFlags, 'data.multipleCapture', false);
			const printerReceiptOption = get(portalFlags, 'data.printerReceiptOption', false);
			const customLabels = get(customDisplayLabels, 'data', false);
			const displayAuthorization = this.getShouldDisplayAuthoriazion(userSettings, data);

			const expanded = { ...this.state.expanded };

			const newState = {
				transactionStatus: status,
				isLoading: false,
				shareEmail: data.xEmail || '',
				shareEmailValid: validators.email(data.xEmail),
				username: user && user.attributes && user.attributes.email,
				setNewCardAsDefault,
				showIssuingBankInfo,
				splitCaptureEnabled,
				printerReceiptOption,
				customDisplayLabels: customLabels,
				isCashSale: toLower(data.xCommand) === 'cash:sale',
				isCheck: includes(toLower(data.xCommand), 'check'),
				token: data.xToken,
				displayAuthorization,
				isErroredToken: isErroredToken(data.xResponseResult, data.xToken),
				isErrored: isErrored(data.xResponseResult),
				isApproved: isApproved(data.xResponseResult),
				hasCustomerBilling: hasCustomerBilling(),
				isNotAllowedPaymentMethod: isNotAllowedPaymentMethod(split(toLower(data.xCommand), ':')[0]),
			};
			const disableAddCustomer = shouldDisableAddCustomer(
				row.xResponseResult,
				hasCustomerBilling,
				isNotAllowedPaymentMethod(split(toLower(row.xCommand), ':')[0]),
				row.xMerchantId,
				row.xVoid,
				principal
			);

			if (get(logoManagement, 'data.includeCoBrandLogoOnReceipts', false)) {
				try {
					newState.logoUrlBase64 = await this.fetchLogo();
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error(e);
				}
			}

			if (this.isProPay(data)) {
				expanded.processingDetails = true;
			}

			if (data.xIssuingBank || data.xIssuingBankPhone || data.xIssuingBankWebsite) {
				expanded.issuingBankInfo = true;
			}

			data.xIsDebit = row.xIsDebit;
			data.xRefnumCurrent = row.xRefnumCurrent;
			data.originalCustomKey = originalCustomKey ? `x${originalCustomKey}` : originalCustomKey;
			data.convenienceCustomKey = convenienceCustomKey ? `x${convenienceCustomKey}` : convenienceCustomKey;

			newState.disableAddCustomer = disableAddCustomer;
			newState.data = data;
			newState.expanded = expanded;

			this.setState(newState);
		} catch (e) {
			this.props.handleError(e);
			this.setState({
				isLoading: false,
			});
		}
	};

	fetchLogo = async () => {
		const { LogoUrl: logoUrl } = await this.props.makePendingRequest(
			logoManagementService.getLogoUrl(),
			requestKeys.LOGO
		);
		let logoUrlBase64 = '';

		if (logoUrl) {
			logoUrlBase64 = await this.props.makePendingRequest(
				logoManagementService.fetchImageAndConvertToBase64(`${logoUrl}?${Date.now()}`),
				requestKeys.FETCH
			);
		}

		return logoUrlBase64;
	};

	get currency() {
		return this.state.data && this.state.data.currency;
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency(this.currency);
	}

	formatCityStateZip = (city, state, zip) => {
		let cityString = city ? `${city}, ` : '';
		let stateString = state ? `${state} ` : '';
		let zipString = zip ? zip : '';
		let str = cityString + stateString + zipString;

		if (str === '') {
			return 'N/A';
		}
		return str;
	};

	redirectToUpgradePlan = () => {
		this.props.history.push({ pathname: '/terminal-only' });
	};

	renderCustomFields = () => {
		const {
			data,
			customDisplayLabels,
			expanded: { customFields },
		} = this.state;

		const customInformation = compact(renderCustomFields(data, customDisplayLabels, this.renderReceiptItem, true));

		if (isEmpty(customInformation)) {
			return;
		}
		return (
			<Fragment>
				<button
					className="info-panel__heading__wrapper is-expandable"
					onClick={() => this.toggleExpand('customFields')}
				>
					<h6 className="info-panel__heading">Custom Information</h6>
					<i className={`icon icon--sml icon--chevron--${customFields ? 'top' : 'down'}--primary`}></i>
				</button>
				{customFields && <div className="info-panel__section">{customInformation}</div>}
			</Fragment>
		);
	};

	/**
	 * Renders Billing/Shipping information
	 * @param type Bill or Ship. Default is Bill
	 * @returns {*}
	 */
	renderBillingDetails = (type = 'Bill') => {
		const { data, isOverflowing, expanded } = this.state;
		const title = type === 'Bill' ? 'Billing Information' : 'Shipping Information';

		const name =
			trim(
				type === 'Bill'
					? `${data.xBillFirstName || ''} ${data.xBillLastName || ''}`
					: `${data.xShipFirstName || ''} ${data.xShipLastName || ''}`
			) || null;
		const company = trim(type === 'Bill' ? data.xBillCompany : data.xShipCompany) || null;
		const phoneNumber = trim(type === 'Bill' ? data.xBillPhone : data.xShipPhone) || null;
		const street = trim(type === 'Bill' ? data.xBillStreet : data.xShipStreet) || null;
		const email = trim(type === 'Bill' ? data.xEmail : data.xShipEmail) || null;

		let cityStateZipArray = [];
		if (type === 'Bill') {
			data.xBillCity ? cityStateZipArray.push(data.xBillCity) : null;
			data.xBillState ? cityStateZipArray.push(data.xBillState) : null;
			data.xBillZip ? cityStateZipArray.push(data.xBillZip) : null;
		} else {
			data.xShipCity ? cityStateZipArray.push(data.xShipCity) : null;
			data.xShipState ? cityStateZipArray.push(data.xShipState) : null;
			data.xShipZip ? cityStateZipArray.push(data.xShipZip) : null;
		}
		const cityStateZipString = cityStateZipArray.join(', ');

		if (!name && !company && !phoneNumber && !street && cityStateZipArray.length == 0 && !email) {
			if (expanded[toLower(type)]) {
				const newExpanded = transform(expanded, (acc, value, key) => {
					if (toLower(type) !== key) {
						acc[key] = value;
					}
				});
				this.setState({ expanded: newExpanded });
			}

			return <Fragment />;
		}

		return (
			<div>
				<button className="info-panel__heading__wrapper is-expandable" onClick={() => this.toggleExpand(toLower(type))}>
					<h6 className="info-panel__heading">{title}</h6>
					<i className={`icon icon--sml icon--chevron--${expanded[toLower(type)] ? 'top' : 'down'}--primary`}></i>
				</button>
				{expanded[toLower(type)] && (
					<div className="info-panel__section">
						{name ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Name:</span>
								<p className="type--p2 type--p2--medium">{name}</p>
							</div>
						) : null}
						{company ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Company:</span>
								<a
									className="btn btn--link datatooltip--auto datatooltip--top-left"
									onClick={() => this.setQueryParamAndRefresh('company', company)}
									href="javascript:void(0)"
									data-tooltip="Filter by company"
								>
									{company}
								</a>
							</div>
						) : null}
						{phoneNumber ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Phone:</span>
								<p className="type--p2 type--p2--medium">{phoneNumber}</p>
							</div>
						) : null}
						{street ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Street:</span>
								<p className="type--p2 type--p2--medium">{street}</p>
							</div>
						) : null}
						{cityStateZipArray.length > 0 ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">
									City, {this.stateLabel}, {this.zipLabel}:
								</span>
								<p className="type--p2 type--p2--medium">{cityStateZipString}</p>
							</div>
						) : null}
						{email ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Email:</span>
								<a
									data-tooltip={`Filter by ${isOverflowing ? email : 'email'}`}
									className="btn btn--link datatooltip--auto datatooltip--top-left"
									onClick={() => this.setQueryParamAndRefresh('email', email)}
									href="javascript:void(0)"
									ref={this.emailRef}
								>
									{email}
								</a>
							</div>
						) : null}
					</div>
				)}
			</div>
		);
	};

	renderProcessingDetails = () => {
		const {
			expanded: { processingDetails },
			data: { xProcessingFee, xRequestAmount, xCommand, xSplitAmount, xAchReturnFee },
		} = this.state;

		const transactionType = split(toLower(xCommand), ':')[1];
		const netSale = xRequestAmount - xProcessingFee;
		return (
			this.isProPay() && (
				<div>
					<button
						className="info-panel__heading__wrapper is-expandable"
						onClick={() => this.toggleExpand('processingDetails')}
					>
						<h6 className="info-panel__heading">Processing Details</h6>
						<i className={`icon icon--sml icon--chevron--${processingDetails ? 'top' : 'down'}--primary`}></i>
					</button>
					{processingDetails && (
						<div className="info-panel__section">
							{transactionType === 'refund' ? (
								<div className="grid-sidebar__field">
									<span className="type--p2 type--color--text--light">Refund fee:</span>
									<NumberFormat
										className="type--p2 type--p2--medium"
										value={xRequestAmount}
										displayType="text"
										thousandSeparator={true}
										prefix={this.currencyCode}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							) : (
								<Fragment>
									<div className="grid-sidebar__field">
										<span className="type--p2 type--color--text--light">Gross amount:</span>
										<NumberFormat
											className="type--p2 type--p2--medium"
											value={xRequestAmount}
											displayType="text"
											thousandSeparator={true}
											prefix={this.currencyCode}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
									</div>
									<div className="grid-sidebar__field">
										<span className="type--p2 type--color--text--light">Processing fee: </span>
										<NumberFormat
											className="type--p2 type--p2--medium"
											value={xProcessingFee}
											displayType="text"
											thousandSeparator={true}
											prefix={this.currencyCode}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
									</div>
									{parseFloat(xSplitAmount) > 0 && (
										<div className="grid-sidebar__field">
											<span className="type--p2 type--color--text--light">Split fee: </span>
											<NumberFormat
												className="type--p2 type--p2--medium"
												value={xSplitAmount}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
									)}
									<div className="grid-sidebar__field">
										<span className="type--p2 type--color--text--light">Net sale: </span>
										<NumberFormat
											className="type--p2 type--p2--medium"
											value={netSale}
											displayType="text"
											thousandSeparator={true}
											prefix={this.currencyCode}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
									</div>
									{parseFloat(xAchReturnFee) > 0 && (
										<div className="grid-sidebar__field">
											<span className="type--p2 type--color--text--light">ACH Return Fee: </span>
											<NumberFormat
												className="type--p2 type--p2--medium"
												value={xAchReturnFee}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
									)}
								</Fragment>
							)}
						</div>
					)}
				</div>
			)
		);
	};

	renderIssuingBankInfo = () => {
		const {
			data: { xIssuingBank, xIssuingBankPhone, xIssuingBankWebsite },
			expanded: { issuingBankInfo },
			showIssuingBankInfo,
		} = this.state;
		const websiteUrl = startsWith(xIssuingBankWebsite, 'http') ? xIssuingBankWebsite : `https://${xIssuingBankWebsite}`;

		return (
			showIssuingBankInfo &&
			(xIssuingBank || xIssuingBankPhone || xIssuingBankWebsite) && (
				<div>
					<button
						className="info-panel__heading__wrapper is-expandable"
						onClick={() => this.toggleExpand('issuingBankInfo')}
					>
						<h6 className="info-panel__heading">Issuing Bank information</h6>
						<i className={`icon icon--nano icon--chevron--${issuingBankInfo ? 'top' : 'down'}--primary`}></i>
					</button>
					{issuingBankInfo && (
						<div className="info-panel__section">
							{xIssuingBank && xIssuingBank.trim() !== '' ? (
								<div className="grid-sidebar__field">
									<span className="type--p2 type--color--text--light">Bank Name:</span>
									<p className="type--p2 type--p2--medium">{xIssuingBank}</p>
								</div>
							) : null}
							{xIssuingBankPhone && xIssuingBankPhone.trim() !== '' ? (
								<div className="grid-sidebar__field">
									<span className="type--p2 type--color--text--light">Bank Phone Number:</span>
									<p className="type--p2 type--p2--medium">{xIssuingBankPhone}</p>
								</div>
							) : null}
							{xIssuingBankWebsite && xIssuingBankWebsite.trim() !== '' ? (
								<div className="grid-sidebar__field">
									<span className="type--p2 type--color--text--light">Bank Website:</span>
									<a className="btn btn--link" rel="noopener noreferrer" target="_blank" href={websiteUrl}>
										{websiteUrl}
									</a>
								</div>
							) : null}
						</div>
					)}
				</div>
			)
		);
	};

	getAdditionalInfoDisplayValue = (value, type, name) => {
		const booleanValue = toNumber(value) ? 'True' : 'False';
		if (includes(toLower(name), 'custom')) {
			return;
		}
		if (type === 'object') {
			return value.format(ApplicationSettings.displayDateTimeFormat);
		} else if (type === 'boolean') {
			return booleanValue;
		} else if ((startsWith(toLower(name), 'is') && value === '0') || value === '1') {
			return booleanValue;
		}
		return value;
	};

	renderVisibleColumnsAdditionalInformation = data => {
		const { visibleColumns } = this.props;

		return compact(
			map(visibleColumns, c => {
				if (!includes(hardcodedKeys, c.key)) {
					const value = data[c.key];
					const name = c.name;
					const type = typeof value;
					const displayValue = this.getAdditionalInfoDisplayValue(value, type, name);

					if (!displayValue) return;
					return (
						<div className="grid-sidebar__field" key={c.key}>
							<span className="type--p2 type--color--text--light">{name}:</span>
							<p className="type--p2 type--p2--medium">{displayValue}</p>
						</div>
					);
				}
			})
		);
	};

	renderAdditionalInformation = () => {
		const {
			data,
			isCheck,
			expanded: { additionalInformation },
		} = this.state;
		const visibleColumnsData = this.renderVisibleColumnsAdditionalInformation(data);
		const hasData =
			!isEmpty(visibleColumnsData) ||
			get(data, 'xDescription', false) ||
			get(data, 'xSignature', false) ||
			(get(data, 'xStatus', false) && !isCheck) ||
			get(data, 'xStatusReason', false);

		return (
			hasData && (
				<Fragment>
					<button
						className="info-panel__heading__wrapper is-expandable"
						onClick={() => this.toggleExpand('additionalInformation')}
					>
						<h6 className="info-panel__heading">Additional information</h6>
						<i className={`icon icon--sml icon--chevron--${additionalInformation ? 'top' : 'down'}--primary`}></i>
					</button>
					{additionalInformation && (
						<div className="info-panel__section">
							{data.xDescription && data.xDescription.trim() !== '' ? (
								<div className="grid-sidebar__field">
									<span className="type--p2 type--color--text--light">Transaction Description:</span>
									<p className="type--p2 type--p2--medium">{data.xDescription}</p>
								</div>
							) : null}
							{data.xSignature && data.xSignature.trim() !== '' ? (
								<div className="grid-sidebar__field">
									<span className="type--p2 type--color--text--light">Signature:</span>
									<div>
										<img src={'data:image/png;base64,' + data.xSignature} className="grid__expansion__signature" />
									</div>
								</div>
							) : null}
							{!isCheck && data.xStatus ? (
								<div className="grid-sidebar__field">
									<span className="type--p2 type--color--text--light">Status:</span>
									<p className="type--p2 type--p2--medium">{data.xStatus}</p>
								</div>
							) : null}
							{data.xStatusReason && data.xStatusReason.trim() !== '' ? (
								<div className="grid-sidebar__field">
									<span className="type--p2 type--color--text--light">Status Description:</span>
									<p className="type--p2 type--p2--medium">{data.xStatusReason}</p>
								</div>
							) : null}
							{!isEmpty(visibleColumnsData) && visibleColumnsData}
						</div>
					)}
				</Fragment>
			)
		);
	};

	refreshGridData = () => {
		this.props.refreshGridData();
	};

	openModal = (modalName, data = {}) => {
		this.props.openModal({
			name: modalName,
			data: data,
		});
	};

	renderStatus = () => {
		const {
			transactionStatus: {
				isVoid,
				isAuthOnly,
				isRefund,
				isRefunded,
				canRefundAmount,
				previouslyRefundedAmount,
				isSplitCaptured,
			},
		} = this.state;
		let statusImage = null;
		let tooltip = null;

		if (isVoid) {
			statusImage = <div className="badge badge--dot badge--error">Voided</div>;
		} else if (isRefund) {
			statusImage = <div className="badge badge--dot badge--warning">Refund</div>;
		} else if (isRefunded) {
			if (canRefundAmount > 0 && previouslyRefundedAmount > 0) {
				tooltip = `Partial refund of ${this.currencyCode}${parseFloat((previouslyRefundedAmount / 10).toFixed(2))}`;
				statusImage = (
					<div alt="Partially Refunded" className="badge badge--dot badge--warning">
						Partially Refunded
					</div>
				);
			} else {
				statusImage = (
					<div alt="Refunded" className="badge badge--dot badge--success">
						Refunded
					</div>
				);
			}
		} else if (isAuthOnly) {
			if (isSplitCaptured) {
				statusImage = (
					<Fragment>
						<div className="badge badge--dot badge--warning">Auth</div>
						<div className="badge badge--dot badge--warning">Split Captured</div>
					</Fragment>
				);
			} else {
				statusImage = <div className="badge badge--dot badge--warning">Auth</div>;
			}
		}

		if (statusImage !== null) {
			return (
				<div className="flex--primary datatooltip--v--bottom datatooltip--w--100" data-tooltip={tooltip}>
					{statusImage}
				</div>
			);
		}
		return null;
	};
	getGeneralInformationCustomLabel = (label, defaultLabel) => {
		const { customDisplayLabels } = this.state;
		return customDisplayLabels[label] || defaultLabel;
	};

	renderTransactionDetails = displayAuthorization => {
		const {
			data,
			transactionStatus,
			data: { xCommand, xResponseAVSCode, xIsSplitCapturable, xClearedAmount, xClearedCount, xRequestAmount },
			expanded: { general },
			token,
		} = this.state;
		let amount = transactionStatus.isVoid ? data.xRequestAmount : data.xAmount;
		let amountClass = transactionStatus.isVoid ? 'type--linethrough type--color--primary' : '';
		const accountNumber =
			data.xMaskedAccountNumber && data.xMaskedAccountNumber.includes('xxx')
				? `**** ${data.xMaskedAccountNumber.slice(-4)}`
				: data.xMaskedAccountNumber;
		let avs = mapAvsItem(xResponseAVSCode);
		if (avs) {
			avs = avs.text;
		}
		const [payment, transaction] = split(toLower(xCommand), ':');
		const tType = toLower(payment) === 'avsonly' ? payment : transaction;
		const issueGift = payment === 'gift' && transaction === 'issue';
		const redeemGift = payment === 'gift' && transaction === 'redeem';
		if (redeemGift) {
			amount = Math.abs(amount);
		}
		const paymentType = PaymentTransactionTypes.getPaymentType(payment);
		const transactionType = PaymentTransactionTypes.getTransactionType(tType);
		return (
			<div>
				<button className="info-panel__heading__wrapper is-expandable" onClick={() => this.toggleExpand('general')}>
					<h6 className="info-panel__heading">General information</h6>
					<i className={`icon icon--sml icon--chevron--${general ? 'top' : 'down'}--primary`}></i>
				</button>
				{general && (
					<div className="info-panel__section">
						<div className=" spc--top--sml--alt spc--bottom--sml--alt">
							<p className="display--b type--p2 type--color--text spc--bottom--sml">{`Amount: ${
								issueGift ? '-' : ''
							}`}</p>
							<div className={`flex--primary flex--gap--sml ${amountClass}`}>
								<NumberFormat
									className="type--h4"
									value={amount}
									displayType="text"
									thousandSeparator={true}
									prefix={this.currencyCode}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
								{xClearedCount && (
									<i
										className="icon icon--sml icon--info align--v--middle spc--left--tny align--v--top"
										data-tooltip="This is the amount that remains after split captures."
									></i>
								)}
								{this.renderStatus()}
							</div>
						</div>

						{displayAuthorization && (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Authorization Amount:</span>
								<NumberFormat
									className="type--p2 type--p2--medium"
									value={xRequestAmount}
									displayType="text"
									thousandSeparator={true}
									prefix={this.currencyCode}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>
						)}
						{xIsSplitCapturable && xClearedCount && (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Cleared Amount:</span>
								<div className="type--p2 type--p2--medium">
									<NumberFormat
										value={xClearedAmount}
										displayType="text"
										thousandSeparator={true}
										prefix={this.currencyCode}
										decimalScale={2}
										fixedDecimalScale={true}
									/>{' '}
									({xClearedCount})
								</div>
							</div>
						)}
						<div className="grid-sidebar__field">
							<span className="type--p2 type--color--text--light">Transaction type:</span>
							<p className="type--p2 type--p2--medium">{transactionType}</p>
						</div>
						<div className="grid-sidebar__field">
							<span className="type--p2 type--color--text--light">Payment type:</span>
							<p className="type--p2 type--p2--medium">{paymentType}</p>
						</div>
						<div className="grid-sidebar__field">
							<span className="type--p2 type--color--text--light">Entered date:</span>
							<p className="type--p2 type--p2--medium">
								{data.xEnteredDate.format(ApplicationSettings.displayDateTimeFormat)}
							</p>
						</div>
						{data && data.xOrderID && data.xOrderID.trim() !== '' ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">
									{this.getGeneralInformationCustomLabel('orderId', 'Order Id')}:
								</span>
								<p className="type--p2 type--p2--medium">{data.xOrderID}</p>
							</div>
						) : null}
						{data && data.xInvoice && data.xInvoice.trim() !== '' ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">
									{this.getGeneralInformationCustomLabel('invoice', 'Invoice')}:
								</span>
								<p className="type--p2 type--p2--medium">{data.xInvoice}</p>
							</div>
						) : null}
						{token && (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Token:</span>
								<p className="type--p2 type--p2--medium">{token}</p>
							</div>
						)}
						<div className="grid-sidebar__field">
							<span className="type--p2 type--color--text--light">Account number:</span>
							<div className="type--p2 type--p2--medium">
								{payment !== 'check' && accountNumber ? (
									<a
										className="btn btn--link datatooltip--auto datatooltip--top-left"
										href={`/transactions?cardNumber=${accountNumber.slice(-4)}&disabled=true`}
										target="_blank"
										data-tooltip="Filter by last 4 digits"
									>
										{accountNumber}
									</a>
								) : (
									accountNumber
								)}
							</div>
						</div>
						{data && data.xCryptoStatus ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Status:</span>
								<p className="type--p2 type--p2--medium">{data.xCryptoStatus}</p>
							</div>
						) : null}
						{data && parseFloat(data.xCryptoProcessingFee) ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Processing Fee:</span>
								<NumberFormat
									className="type--p2 type--p2--medium"
									value={data.xCryptoProcessingFee}
									displayType="text"
									thousandSeparator={true}
									prefix={this.currencyCode}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>
						) : null}
						{data && parseFloat(data.xCryptoTransactionFee) ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Transaction Fee:</span>
								<NumberFormat
									className="type--p2 type--p2--medium"
									value={data.xCryptoTransactionFee}
									displayType="text"
									thousandSeparator={true}
									prefix={this.currencyCode}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>
						) : null}
						{data && parseFloat(data.xCryptoNetworkFee) ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Network Fee:</span>
								<NumberFormat
									className="type--p2 type--p2--medium"
									value={data.xCryptoNetworkFee}
									displayType="text"
									thousandSeparator={true}
									prefix={this.currencyCode}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>
						) : null}
						{data && parseFloat(data.xTax) ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Tax:</span>
								<NumberFormat
									className="type--p2 type--p2--medium"
									value={data.xTax}
									displayType="text"
									thousandSeparator={true}
									prefix={this.currencyCode}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>
						) : null}
						{data && parseFloat(data.xServiceFee) ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Service Fee:</span>
								<NumberFormat
									className="type--p2 type--p2--medium"
									value={data.xServiceFee}
									displayType="text"
									thousandSeparator={true}
									prefix={this.currencyCode}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>
						) : null}
						{avs ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">AVS:</span>
								<p className="type--p2 type--p2--medium">{avs}</p>
							</div>
						) : null}
						{this.renderReceiptItem(
							`${this.getGeneralInformationCustomLabel('avsStreet', 'AVS Street')}:`,
							data.xStreet
						)}
						{this.renderReceiptItem(
							`${this.getGeneralInformationCustomLabel('avsZip', `AVS ${this.zipLabel}`)}:`,
							data.xZip
						)}
						{data && data.xSerialNumber && data.xSerialNumber.trim() !== '' ? (
							<div className="grid-sidebar__field">
								<span className="type--p2 type--color--text--light">Serial Number</span>
								<p className="type--p2 type--p2--medium">{data.xSerialNumber}</p>
							</div>
						) : null}
					</div>
				)}
			</div>
		);
	};
	renderMultiValues = (name, values) => {
		if (isArray(values)) {
			return values.map((e, index) => (
				<div key={index} className="grid__expansion__value">
					{e}
				</div>
			));
		} else {
			return (
				<div className="grid__expansion__value">
					{includes(['electronic transfer fee', 'original amount', 'service fee', 'tax'], toLower(name))
						? this.renderNumFormat(values)
						: values}
				</div>
			);
		}
	};
	renderNumFormat = value => {
		return (
			<NumberFormat
				prefix={this.currencyCode}
				value={value}
				displayType="text"
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
			/>
		);
	};

	renderReceiptItem = (name, value, renderEmpty = false) => {
		const { data } = this.state;
		const customerId = data.xCustomerID || data.xCustom01;
		const isCustomerId = value === customerId && /^[a-z]\d+/.test(value);
		const displayTooltip = startsWith(toLower(name), 'electronic transfer');
		if ((name && value) || renderEmpty) {
			return (
				<div className="grid-sidebar__field">
					<span className="flex--primary type--p2 type--color--text--light">
						{name}
						{displayTooltip && (
							<i
								data-tooltip="This field was previously referred to as 'Convenience Fee'"
								className="icon icon--tny icon--regular--info spc--left--tny datatooltip--w--200"
							></i>
						)}
					</span>
					{isCustomerId ? (
						<a
							className="btn btn--link"
							href={`/customers/report?customerId=${this.state.data.xCustom01}&expandedRow=${this.state.data.xCustom01}`}
							target="_blank"
						>
							{value}
						</a>
					) : (
						<p className="type--p2 type--p2--medium">{this.renderMultiValues(name, value)}</p>
					)}
				</div>
			);
		}
	};

	getCustomFieldLabel = label => {
		const { data } = this.state;
		const { convenienceCustomKey, originalCustomKey } = data;
		let title;
		if (label === convenienceCustomKey) title = 'Electronic Transfer Fee';
		if (label === originalCustomKey) title = 'Original Amount';

		return title;
	};
	renderVoidButton = () => {
		const {
			transactionStatus: { canVoid, isVoid, isApproved },
			permissions: { allowCcVoid, allowCheckVoid },
			data: { xResponseResult, xRefNum, xCommand, xCCType },
		} = this.state;
		const isOJC = toLower(xCCType) === 'ojc';
		const { row } = this.props;
		const paymentType = split(toLower(xCommand), ':')[0];
		const allowVoid = paymentType === 'cc' || paymentType === 'grant' ? allowCcVoid : allowCheckVoid;

		const isVoidable = paymentType === 'crypto' || isOJC ? false : allowVoid;
		const isSplitCapture = toLower(xCommand) === 'cc:splitcapture';

		if (isApproved || toLower(xResponseResult) === 'declined') {
			if (isVoid) {
				return (
					<li data-tooltip="The transaction is voided" className="datatooltip--w--200">
						<button type="button" className="btn btn--link btn--link--tertiary" disabled={true}>
							<i className="icon icon--lrg icon--close"></i>
							<span>Void</span>
						</button>
					</li>
				);
			}

			if (canVoid && !isSplitCapture) {
				let tooltip = null;
				if (this.hasTerminalOnly) {
					tooltip = featurePackageTooltips.hasTerminalOnly;
				} else if (!isVoidable) {
					tooltip = 'Permission required';
				}
				return (
					<li data-tooltip={tooltip} className="datatooltip--w--200">
						<button
							type="button"
							className="btn btn--link btn--link--tertiary"
							disabled={!isVoidable || this.hasTerminalOnly}
							onClick={() =>
								this.openModal(modalNames.void, {
									refNum: xRefNum,
									type: xCommand,
									refreshGridData: this.refreshGridData,
									xMerchantId: row.xMerchantId,
								})
							}
						>
							<i className="icon icon--lrg icon--close"></i>
							<span>Void</span>
						</button>
					</li>
				);
			} else {
				return (
					<li data-tooltip="The transaction cannot be voided" className="datatooltip--w--200">
						<button type="button" className="btn btn--link btn--link--tertiary" disabled={true}>
							<i className="icon icon--lrg icon--close"></i>
							<span>Void</span>
						</button>
					</li>
				);
			}
		}
	};

	renderRefundButton = () => {
		const {
			transactionStatus: { isApproved, isRefunded, isVoid, canRefund, canRefundAmount, isRefund },
			data,
			data: { xResponseResult, xRefNum: refNum, xAmount: amount, xCommand: type, xCurrency: currency },
			permissions: { allowCcRefund, allowCheckRefund },
		} = this.state;
		const { row } = this.props;
		const email = data && data.xEmail;
		const paymentType = split(toLower(type), ':')[0];
		const allowRefund = paymentType === 'cc' ? allowCcRefund : allowCheckRefund;
		const isRefundable = paymentType === 'crypto' ? false : allowRefund;

		if (isApproved || toLower(xResponseResult) === 'declined') {
			if (isRefunded && !isVoid) {
				if (canRefund && canRefundAmount > 0) {
					return (
						<Fragment>
							{!isRefundable ? (
								<li
									data-tooltip={this.hasTerminalOnly ? featurePackageTooltips.hasTerminalOnly : 'Permission required'}
									className="datatooltip--w--200"
								>
									<button className="btn btn--link btn--link--tertiary" disabled={true}>
										<i className="icon icon--lrg icon--refund--light"></i>
										<span>Refund</span>
									</button>
								</li>
							) : (
								<li
									data-tooltip={
										this.hasTerminalOnly
											? featurePackageTooltips.hasTerminalOnly
											: 'The transaction is partially refunded'
									}
									className="datatooltip--w--200"
								>
									<button
										type="button"
										className="btn btn--link btn--link--tertiary"
										disabled={this.hasTerminalOnly}
										onClick={() =>
											this.openModal(modalNames.refund, {
												refNum,
												amount: canRefundAmount,
												type,
												currency,
												refreshGridData: this.refreshGridData,
												email,
												xMerchantId: row.xMerchantId,
											})
										}
									>
										<i className="icon icon--lrg icon--refund--light"></i>
										<span>Refund</span>
									</button>
								</li>
							)}
						</Fragment>
					);
				}
			}

			if (isRefund && !isVoid) {
				return (
					<li data-tooltip="The transaction is refunded" className="datatooltip--w--200">
						<button type="button" className="btn btn--link btn--link--tertiary" disabled={true}>
							<i className="icon icon--lrg icon--refund--light"></i>
							<span>Refund</span>
						</button>
					</li>
				);
			}

			if (canRefund) {
				return (
					<Fragment>
						{allowRefund ? (
							<li
								data-tooltip={this.hasTerminalOnly ? featurePackageTooltips.hasTerminalOnly : null}
								className="datatooltip--w--200"
							>
								<button
									className="btn btn--link btn--link--tertiary"
									disabled={this.hasTerminalOnly}
									onClick={() => {
										this.openModal(modalNames.refund, {
											refNum,
											amount,
											type,
											currency,
											refreshGridData: this.refreshGridData,
											email,
											xMerchantId: row.xMerchantId,
										});
									}}
								>
									<i className="icon icon--lrg icon--refund--light"></i>
									<span>Refund</span>
								</button>
							</li>
						) : (
							<li
								data-tooltip={this.hasTerminalOnly ? featurePackageTooltips.hasTerminalOnly : 'Permission required'}
								className="datatooltip--w-200"
							>
								<button type="button" className="btn btn--link btn--link--tertiary" disabled={true}>
									<i className="icon icon--lrg icon--refund--light"></i>
									<span>Refund</span>
								</button>
							</li>
						)}
					</Fragment>
				);
			} else {
				return (
					<li data-tooltip="The transaction cannot be refunded" className="datatooltip--w--200">
						<button type="button" className="btn btn--link btn--link--tertiary" disabled={true}>
							<i className="icon icon--lrg icon--refund--light"></i>
							<span>Refund</span>
						</button>
					</li>
				);
			}
		}
	};

	renderCaptureButton = () => {
		const {
			transactionStatus: { isVoid, isApproved, isSplitCaptured, canSplitCapture },
			permissions: { allowCcCapture },
			data,
			transactionStatus,
		} = this.state;
		const { autoPartialAuthReversal, splitCaptureEnabled, row } = this.props;
		const splitCapturedAndSettingDisabled = canSplitCapture && isSplitCaptured && !splitCaptureEnabled;
		const disabled = isVoid || !allowCcCapture || this.hasTerminalOnly || splitCapturedAndSettingDisabled;

		let component = null;
		let tooltip = null;
		if (this.hasTerminalOnly) {
			tooltip = featurePackageTooltips.hasTerminalOnly;
		} else if (!allowCcCapture) {
			tooltip = 'Permission required';
		} else if (isVoid) {
			tooltip = 'The transaction cannot be captured';
		} else if (splitCapturedAndSettingDisabled) {
			tooltip = 'Transaction was split captured and can no longer be captured. Enable split capture to continue';
		}

		if (this.isAuthOnly && !this.voided && isApproved) {
			component = (
				<button
					type="button"
					className="btn btn--link btn--link--tertiary datatooltip--auto"
					disabled={disabled}
					onClick={() => {
						this.openModal(modalNames.capture, {
							autoPartialAuthReversal,
							row: data,
							transactionStatus,
							refreshGridData: this.refreshGridData,
							splitCaptureEnabled,
							xMerchantId: row.xMerchantId,
						});
					}}
				>
					<i className="icon icon--lrg icon--capture--light"></i>
					<span>Capture</span>
				</button>
			);
		}

		return (
			<li className={splitCapturedAndSettingDisabled ? 'datatooltip--w--200' : ''} data-tooltip={tooltip}>
				{component}
			</li>
		);
	};

	renderCloseSplitCapturedAuthOnlyButton = () => {
		const {
			transactionStatus: { isSplitCaptured },
			data: row,
		} = this.state;

		return (
			isSplitCaptured && (
				<button
					type="button"
					className="btn btn--link btn--link--tertiary datatooltip--auto"
					data-tooltip="Close Split Capture"
					onClick={() =>
						this.openModal(modalNames.confirmAction, {
							loadingMessage: 'Closing Split Capture',
							question: 'This action cannot be undone. Are you sure you want to close Split Capture?',
							onConfirm: () => this.closeSplitCapturedAuthOnly(row),
						})
					}
				>
					<i className="icon icon--lrg icon--close"></i>
				</button>
			)
		);
	};

	renderAdjustButton = () => {
		const {
			row: { currency, xMerchantId },
			handleError,
		} = this.props;
		const {
			data: {
				xRefNum: refNum,
				xCommand: type,
				xAmount: amount,
				xTip: tip,
				xDescription: description,
				xOrderID: orderId,
				xCustom01: custom1,
				xCustom02: custom2,
				xCustom03: custom3,
				xResponseResult,
				xRefnumCurrent,
			},
			transactionStatus: { canAdjust, isApproved },
			permissions: { allowCcAdjust, allowCheckAdjust },
			customDisplayLabels,
		} = this.state;
		let component = null;
		const paymentType = split(toLower(type), ':')[0];
		const allowAdjust = paymentType === 'cc' ? allowCcAdjust : allowCheckAdjust;

		if (isApproved || toLower(xResponseResult) === 'declined') {
			if (canAdjust) {
				component = (
					<li
						data-tooltip={
							this.hasTerminalOnly
								? featurePackageTooltips.hasTerminalOnly
								: !allowAdjust
								? 'Permission required'
								: null
						}
						className="datatooltip--w--200"
					>
						<button
							type="button"
							className="btn btn--link btn--link--tertiary datatooltip--auto"
							disabled={this.hasTerminalOnly || !allowAdjust}
							onClick={() => {
								this.openModal(modalNames.adjust, {
									refNum,
									type,
									amount,
									tip,
									description,
									orderId,
									custom1,
									custom2,
									custom3,
									currency,
									xRefnumCurrent,
									refreshGridData: this.refreshGridData,
									handleError,
									xMerchantId,
									customDisplayLabels,
								});
							}}
						>
							<i className="icon icon--lrg icon--edit--light"></i>
							<span>Adjust</span>
						</button>
					</li>
				);
			} else {
				component = (
					<li data-tooltip="The transaction cannot be adjusted" className="datatooltip--w--200">
						<button type="button" className="btn btn--link btn--link--tertiary" disabled={true}>
							<i className="icon icon--lrg icon--edit--light"></i>
							<span>Adjust</span>
						</button>
					</li>
				);
			}
		}

		return <Fragment>{component}</Fragment>;
	};

	renderNewTransactionButton = () => {
		const {
			transactionStatus: { isRefund },
			data,
			data: { xCommand, xToken, xResponseResult },
		} = this.state;

		if (toLower(xResponseResult) === 'error' && !xToken) return;

		const principal = principalService.get();
		const canProcessNewTransaction = principal.hasAccess[sectionKeys.newTransaction];
		const isSplitCapture = toLower(xCommand) === 'cc:splitcapture';
		const isEbt = includes(toLower(xCommand), 'ebt');
		const isCrypto = includes(toLower(xCommand), 'crypto');

		const disabled =
			isRefund || !canProcessNewTransaction || isEbt || isSplitCapture || isCrypto || this.state.isCashSale;
		if (disabled) return;

		return (
			<button
				type="button"
				className="btn btn--med btn--primary"
				disabled={disabled}
				onClick={() => {
					if (this.hasTerminalOnly) {
						this.redirectToUpgradePlan();
					} else {
						this.openModal(modalNames.newTransaction, {
							existingTransaction: data,
							refreshGridData: this.refreshGridData,
							xMerchantId: this.props.row.xMerchantId,
						});
					}
				}}
			>
				New Transaction
			</button>
		);
	};

	openEmailSender = () => {
		this.toggleSelectAction('expandHeader');
		this.setState({ showShareEmail: !this.state.showShareEmail });
	};

	handleEmailChange = ({ target: { value } }) => {
		this.setState({ shareEmail: value, shareEmailValid: validators.email(value) });
	};

	handleSendCopyChange = e => {
		const val = e.target.checked;
		this.setState({ sendCopy: val });
	};

	handlePrintError = (method, error) => {
		const { handleError } = this.props;
		if (handleError(error, { additionalInfo: { method } })) {
			this.setState({ isPrinting: false });
		}
	};

	handleAfterPrint = () => {
		this.setState({ isPrinting: false, expandHeader: false });
	};

	handleOnBeforeGetContent = () => {
		return new Promise(resolve => {
			this.setState({ isPrinting: true }, resolve);
		});
	};

	handleEmailNotificationVisible = () => {
		this.setState({ emailNotificationVisible: !this.state.emailNotificationVisible });
	};

	handleEmailSend = async () => {
		const { shareEmail, shareEmailValid, username, sendCopy, data } = this.state;
		this.setState({ isLoading: true }, async () => {
			try {
				if (shareEmailValid) {
					let emails = shareEmail;
					if (sendCopy && username) {
						emails += `,${username}`;
					}
					const rsp = await this.props.makePendingRequest(
						transactionService.sendReceipt(emails, data.xRefNum, this.props.row.xMerchantId),
						requestKeys.FETCH
					);
					this.openEmailSender();
					let success = rsp.xStatus === 'Success';
					this.notificationRef.current.addNotification({
						message: success ? 'Transaction receipt was emailed successfully' : rsp.xError,
						ref: rsp.xRefNum,
						success: success,
					});
				}
			} catch (error) {
				this.props.handleError(error);
			} finally {
				this.setState({
					shareEmailSubmitted: true,
					isLoading: false,
				});
			}
		});
	};

	renderSendEmailButton = () => {
		const {
			data: { xCommand },
		} = this.state;
		const transactionType = split(toLower(xCommand), ':')[1];

		return (
			!includes(['splitcapture', 'save'], transactionType) && (
				<button className="btn btn--link btn--link--tertiary" onClick={() => this.openEmailSender()}>
					<i className="icon icon--lrg icon--mail--light" />
					<span>Email transaction receipt</span>
				</button>
			)
		);
	};

	renderPrintButton = () => {
		const {
			isPrinting,
			data,
			transactionStatus,
			isLoading,
			logoUrlBase64,
			printerReceiptOption,
			customDisplayLabels,
		} = this.state;
		return (
			<Fragment>
				<ReactToPrint
					trigger={() => (
						<li>
							<button disabled={isPrinting} className="btn btn--link btn--link--tertiary">
								<i className="icon icon--lrg icon--print--light" />
								<span>Print transaction receipt</span>
							</button>
						</li>
					)}
					content={() => this.print}
					onPrintError={this.handlePrintError}
					onBeforeGetContent={this.handleOnBeforeGetContent}
					onAfterPrint={this.handleAfterPrint}
				/>
				<span style={{ display: 'none' }}>
					{renderIf(data && !isLoading)(
						<PrintTransactionReceiptComponent
							ref={el => (this.print = el)}
							data={{ ...data, ...transactionStatus }}
							logoUrlBase64={logoUrlBase64}
						/>
					)}
				</span>
				{printerReceiptOption && (
					<Fragment>
						<ReactToPrint
							trigger={() => (
								<li>
									<button disabled={isPrinting} className="btn btn--link btn--link--tertiary">
										<i className="icon icon--lrg icon--receipt-print--light" />
										<span>Printer Receipt</span>
									</button>
								</li>
							)}
							content={() => this.printerReceipt}
							onPrintError={this.handlePrintError}
							onBeforeGetContent={this.handleOnBeforeGetContent}
							onAfterPrint={this.handleAfterPrint}
							customPageStyle="@page { width: 300px; margin: 10%; } }"
						/>
						<span style={{ display: 'none' }}>
							{renderIf(data && !isLoading)(
								<PrinterReceipt
									ref={el => (this.printerReceipt = el)}
									data={{ ...data, ...transactionStatus, customDisplayLabels }}
									logoUrlBase64={logoUrlBase64}
								/>
							)}
						</span>
					</Fragment>
				)}
			</Fragment>
		);
	};

	renderReceiptViewButton = () => {
		const {
			receiptVisible,
			data: { xRefNum },
			emailNotificationVisible,
			printerReceiptOption,
			isCashSale,
		} = this.state;

		return (
			<Fragment>
				<button className="btn btn--link btn--link--tertiary" onClick={this.openReceiptViewModal}>
					<i className="icon icon--lrg icon--view--light" />
					<span>View transaction receipt</span>
				</button>

				<Modal
					isOpen={receiptVisible}
					hideCloseButton={emailNotificationVisible}
					onClose={this.closeReceiptViewModal}
					className="modal__content modal--med"
				>
					<TransactionReceipt
						emailNotificationVisible={emailNotificationVisible}
						handleEmailNotificationVisible={this.handleEmailNotificationVisible}
						closeModal={this.closeReceiptViewModal}
						refNum={xRefNum}
						xMerchantId={this.props.row.xMerchantId}
						printerReceiptOption={printerReceiptOption}
						isCashSale={isCashSale}
					/>
				</Modal>
			</Fragment>
		);
	};

	download = () => {
		const exporter = new ExportToCsv({ filename: generateFileName(false, this.props.row.xRefNum), ...exportOptions });
		const data = this.replaceColumns(cloneDeep(this.props.row), this.props.visibleColumns);
		if (data && exportService.shouldSubtract(head(data).Command)) {
			head(data).Amount = -Math.abs(head(data).Amount);
		}
		exporter.generateCsv(data);
		this.setState({ expandHeader: false });
	};

	replaceColumns = (data, columnsSource) => {
		const columns = this.getColumns(columnsSource);
		if (data) {
			let newObj = {};
			const columnKeys = Object.keys(columns);
			for (let colKey of columnKeys) {
				const newPropName = columns[colKey];
				if (data[colKey] !== undefined) {
					newObj[newPropName] = data[colKey];
				}
			}
			return [newObj];
		}
		return [];
	};

	getColumns = columns => {
		let returnObj = [];
		for (let col of columns) {
			returnObj[col.key] = col.name.replace(/\u00AD/g, '');
		}
		return returnObj;
	};

	openReceiptViewModal = () => {
		this.setState({ receiptVisible: true, emailNotificationVisible: false });
	};

	closeReceiptViewModal = () => {
		this.setState({ receiptVisible: false, emailNotificationVisible: false, expandHeader: false });
	};

	renderExportTransaction = () => {
		return (
			<button className="btn btn--link btn--link--tertiary" onClick={() => this.download()}>
				<i className="icon icon--lrg icon--download--light" />
				<span>Download transaction</span>
			</button>
		);
	};

	renderEmailSenderPopup = () => {
		const { shareEmail, shareEmailValid, shareEmailSubmitted, sendCopy } = this.state;

		return (
			<div className="modal">
				<div className="modal__body">
					<div className="flex--primary flex--gap--sml--alt flex--no-wrap">
						<input
							type="text"
							name="email"
							className="input input--med"
							placeholder="E-mail"
							onChange={this.handleEmailChange}
							autoComplete="new-password"
							value={shareEmail}
						/>
					</div>

					{!shareEmailValid && shareEmailSubmitted ? (
						<div className="type--validation spc--bottom--med">Please enter a valid email address</div>
					) : null}

					<div className="spc--top--sml">
						<input
							type="checkbox"
							name="sendCopy"
							className="input--check"
							checked={sendCopy}
							onChange={this.handleSendCopyChange}
							id="sendCopy"
						/>
						<label htmlFor="sendCopy">Send me an email of this receipt</label>
					</div>
				</div>
				<div className="modal__footer">
					<button onClick={this.handleEmailSend} className="btn btn--med btn--primary">
						Send
					</button>
				</div>
			</div>
		);
	};
	renderLinkPaymentMethodOrCustomer = data => {
		const {
			disableAddCustomer,
			isApproved,
			isErroredToken,
			hasCustomerBilling,
			isNotAllowedPaymentMethod,
		} = this.state;
		if (this.hideLinkPaymentButtons) return null;
		return (
			<Fragment>
				{
					<RenderLinkPaymentMethodOrCustomer
						row={data}
						principal={this.principal}
						openLinkTransactionPopup={this.openLinkTransactionPopup}
						disabled={disableAddCustomer}
						isNotAllowedPaymentMethod={isNotAllowedPaymentMethod}
						isErroredToken={isErroredToken}
						hasCustomerBilling={hasCustomerBilling}
						isApproved={isApproved}
					/>
				}
				{
					<RenderLinkPaymentMethodOrCustomer
						row={data}
						principal={this.principal}
						isLinkPaymentMethod={true}
						openLinkTransactionPopup={this.openLinkTransactionPopup}
						disabled={disableAddCustomer}
						isNotAllowedPaymentMethod={isNotAllowedPaymentMethod}
						isErroredToken={isErroredToken}
						hasCustomerBilling={hasCustomerBilling}
						isApproved={isApproved}
					/>
				}
			</Fragment>
		);
	};
	renderFooterButtons = () => [
		this.renderVoidButton(),
		this.renderRefundButton(),
		this.renderAdjustButton(),
		this.renderCaptureButton(),
		this.renderCloseSplitCapturedAuthOnlyButton(),
	];
	toggleSelectAction = type => () => {
		this.setState({
			[type]: !this.state[type],
		});
	};
	closeSelectAction = type => () => {
		if (!this.state[type]) {
			return;
		}
		this.setState({
			[type]: false,
		});
	};
	renderGridFooter = data => {
		if (this.props.hideFooter) return null;

		return (
			<div className={`grid-sidebar__footer${this.state.expandFooter ? ' popover__wrapper' : ''}`}>
				{this.renderNewTransactionButton()}
				{!this.hideFooter && (
					<OutsideClick action={this.closeSelectAction('expandFooter')}>
						<button
							className={`btn btn--action btn--action--${!this.state.expandFooter ? 'primary' : 'secondary'} pos--rel`}
							onClick={this.toggleSelectAction('expandFooter')}
						>
							<i className={`icon icon--sml icon--${!this.state.expandFooter ? 'menu--white' : 'close'}`}></i>
						</button>
						{this.state.expandFooter ? (
							<div className="popover popover--sidebar">
								<ul className="popover__list">
									{this.renderLinkPaymentMethodOrCustomer(data)}
									{toLower(data.xResponseResult) !== 'error' && this.renderFooterButtons()}
								</ul>
							</div>
						) : null}
					</OutsideClick>
				)}
			</div>
		);
	};
	renderHeaderButtons = () => {
		const { isCashSale, showShareEmail, isLoading } = this.state;
		if (this.props.hideHeaderButtons) return null;
		return (
			<Fragment>
				{!isCashSale && this.renderPrintButton()}
				{!isCashSale && (
					<li className="pos--rel">
						{renderIf(!showShareEmail)(this.renderSendEmailButton())}
						{renderIf(showShareEmail)(
							<Fragment>
								{this.renderSendEmailButton()}
								<Modal
									onClose={() => this.setState({ ...initialEmailPopupState, expandHeader: false })}
									isOpen={showShareEmail}
									displayProgress={isLoading}
									isLoading={isLoading}
								>
									{this.renderEmailSenderPopup()}
								</Modal>
							</Fragment>
						)}
					</li>
				)}
				<li>{this.renderExportTransaction()}</li>
				<li>{this.renderReceiptViewButton()}</li>
			</Fragment>
		);
	};
	render() {
		const { data, isLoading, isCheck, displayAuthorization } = this.state;

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				<div ref={this.props.setDetailsRef} className="grid-sidebar">
					{isLoading ? (
						<div className="loader__holder fullheight">
							<div className="loader__spinner"></div>
						</div>
					) : null}
					{data ? (
						<Fragment>
							<div className="grid-sidebar__header">
								<div className="flex--tertiary spc--bottom--med">
									<div>
										<span className="type--p4 type--color--text--light">Ref #:</span>
										<p className="type--h5 type--wgt--medium">{data.xRefNum}</p>
									</div>
									<div className="flex--primary flex--gap--sml">
										<div className={this.state.expandHeader ? ' popover__wrapper' : ''}>
											<OutsideClick className={'pos--rel'} action={this.closeSelectAction('expandHeader')}>
												<button
													className={`btn btn--action btn--action--secondary`}
													onClick={this.toggleSelectAction('expandHeader')}
												>
													<i
														className={`icon icon--sml icon--${this.state.expandHeader ? 'more--primary' : 'more'}`}
													></i>
												</button>
												{this.state.expandHeader && (
													<div className="popover popover--down-left popover--sidebar">
														<ul className="popover__list">{this.renderHeaderButtons()}</ul>
													</div>
												)}
											</OutsideClick>
										</div>
										<button className="btn btn--action btn--action--secondary" onClick={this.closeRow}>
											<i className="icon icon--sml icon--close"></i>
										</button>
									</div>
								</div>

								<div className="flex--tertiary flex--gap--sml">
									<StatusComponent
										value={data.xResponseResult}
										error={data.xResponseError}
										badgeType="badge--start-icon"
									/>

									{isCheck && (
										<div className="flex--primary flex--gap--sml">
											<p className="type--p3 type--color--text--light">ACH Status:</p>
											<StatusFraudComponent value={data.xStatus} showBadge="true" />
										</div>
									)}
								</div>
							</div>

							{this.renderTransactionDetails(displayAuthorization)}
							{this.renderBillingDetails('Bill')}
							{this.renderBillingDetails('Ship')}
							{this.renderProcessingDetails()}
							{this.renderIssuingBankInfo()}
							{this.renderCustomFields()}
							{this.renderAdditionalInformation()}

							{this.renderGridFooter(data)}
						</Fragment>
					) : null}
				</div>
			</Fragment>
		);
	}
}

RowDetailsComponent.propTypes = {
	row: PropTypes.object,
	visibleColumns: PropTypes.array,
	refreshGridData: PropTypes.func,
	openModal: PropTypes.func,
	setDetailsRef: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func.isRequired,
	handleError: PropTypes.func,
	history: PropTypes.object,
	closeRow: PropTypes.func.isRequired,
	autoPartialAuthReversal: PropTypes.bool,
	splitCaptureEnabled: PropTypes.bool,
	hideFooter: PropTypes.bool,
	hideHeaderButtons: PropTypes.bool,
	inPopup: PropTypes.bool,
};

export default withCancelable(withError(withLoader(withForwardRef(RowDetailsComponent, withRouter))));
