import React from 'react';
import PropTypes from 'prop-types';

const TransactionRowGridActionsTooltip = ({ tooltip, infoDimensions }) => {
	if (!tooltip) {
		return null;
	}
	return (
		<ul
			className="action-icons"
			style={{
				bottom: `calc(100% - ${infoDimensions.height || 0}px)`,
			}}
		>
			{tooltip}
		</ul>
	);
};

TransactionRowGridActionsTooltip.propTypes = {
	tooltip: PropTypes.any,
	infoDimensions: PropTypes.object,
};

export default TransactionRowGridActionsTooltip;
