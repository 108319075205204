import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { OutsideClick, ResizeSensor } from 'common/utilities';

class GridFooter extends Component {
	state = { expanded: false };

	componentDidMount = () => {
		const { gridFooterRef } = this.props;
		if (gridFooterRef.current) {
			ResizeSensor(gridFooterRef.current, this.hidePopup);
		}
	};

	handleToggle = (_, close) => {
		if (close && !this.state.expanded) return;
		this.setState({ expanded: !this.state.expanded });
	};

	hidePopup = () => {
		this.setState({
			expanded: false,
		});
	};

	render() {
		const {
			gridFooterRef,
			isLoadMoreEnabled,
			loadMoreOptions,
			fetchingAdditionalData,
			fetchingData,
			filteredRows,
			type,
			openCloseModal,
			CustomComponent,
			onLoadMoreLimitChange,
			loadMoreLimit,
			displayErrorRefNum,
		} = this.props;

		const displayCustomComponent = (!fetchingData && filteredRows.length > 0) || (!fetchingData && displayErrorRefNum);

		return (
			<div className="react-grid__footer" ref={gridFooterRef}>
				{isLoadMoreEnabled && (
					<div className="pagination">
						{map(loadMoreOptions, item => (
							<button
								key={item}
								onClick={() => onLoadMoreLimitChange(item)}
								className={`${loadMoreLimit === item ? 'is-active' : ''} pagination__item`}
								disabled={fetchingAdditionalData}
							>
								{item || 'All'}
							</button>
						))}
					</div>
				)}
				<OutsideClick className="react-grid__footer__popup__wrapper" action={() => this.handleToggle(null, true)}>
					<span className={this.state.expanded ? 'react-grid__footer__popup is-open' : 'react-grid__footer__popup'}>
						{fetchingAdditionalData && <div className="loader--progress" />}
						{displayCustomComponent && (
							<CustomComponent openCloseModal={openCloseModal} type={type} data={filteredRows} />
						)}
					</span>
				</OutsideClick>
			</div>
		);
	}
}

GridFooter.propTypes = {
	gridFooterRef: PropTypes.any,
	isLoadMoreEnabled: PropTypes.bool,
	loadMoreOptions: PropTypes.any,
	fetchingAdditionalData: PropTypes.bool,
	fetchingData: PropTypes.bool,
	filteredRows: PropTypes.array,
	type: PropTypes.string,
	openCloseModal: PropTypes.func,
	CustomComponent: PropTypes.any,
	onLoadMoreLimitChange: PropTypes.func,
	loadMoreLimit: PropTypes.any,
	displayErrorRefNum: PropTypes.any,
};

export default GridFooter;
