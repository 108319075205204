import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, includes } from 'lodash';

import sectionKeys from '../../routing/sections';
import { principalService } from '../../Common/services';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { LogoManagement, SendPaymentRequestSettings, UsersComponent } from 'components/settings/pages';
import SettingsTemplate from 'components/settings/pages/SettingsTemplate';
import AccountManagementRecurringSettings from './pages/AccountManagementRecurringSettings';
import UserSettingsAdditionalFunctionality from 'components/user-settings/pages/UserSettingsAdditionalFunctionality';
import AccountManagementTransactionHistory from './pages/AccountManagementTransactionHistory';
import AccountManagementTransactionSettings from './pages/AccountManagementTransactionSettings';
import AccountManagementNewCustomers from './pages/AccountManagementNewCustomers';
import { Redirect } from 'react-router-dom';

let principal = principalService.get();
let userRole = get(principal, 'idInfo.permissions.role');
principalService.subscribe(newPrincipal => {
	principal = newPrincipal;
	userRole = get(newPrincipal, 'idInfo.permissions.role');
});

const isFraud = () => {
	return principal && principal.hasAccess && principal.hasAccess[sectionKeys.fraud];
};

const routes = [
	{
		path: '/user-settings',
		component: () => {
			return <Redirect to="/settings/user-settings" />;
		},
		label: 'User Settings',
		hidden: isFraud,
		isMain: true,
		tooltip: 'Individual user preferences and options',
	},
	{
		path: '/account-settings',
		component: () => {
			return <Redirect to="/settings/account-settings" />;
		},
		label: 'Portal Settings',
		isMain: true,
		tooltip: 'Settings that apply across the portal and impact all users',
		disabled: true,
	},
	{
		path: '/new-transaction',
		component: AccountManagementTransactionSettings,
		label: 'New Transaction Settings',
		hidden: isFraud,
		section: sectionKeys.transactions,
	},
	{
		path: '/transaction-history',
		component: AccountManagementTransactionHistory,
		label: 'Transaction History Settings',
		hidden: isFraud,
		isDefault: () => userRole === 'saveonly',
	},
	{
		path: '/new-customers',
		component: AccountManagementNewCustomers,
		label: 'New Customer Settings',
		hidden: () => isFraud(),
		isDefault: () =>
			principal && principal.idInfo && principal.idInfo.permissions && !principal.idInfo.permissions.allowReportAll,
	},
	{
		path: '/recurring-settings',
		component: AccountManagementRecurringSettings,
		label: 'Recurring Settings',
		section: sectionKeys.dashboard,
		hidden: isFraud,
	},
	{
		path: '/send-payment-request',
		component: SendPaymentRequestSettings,
		label: 'Send Payment Request Management',
		section: sectionKeys.newTransaction,
		hidden: isFraud,
	},
	{
		path: '/logo-management',
		component: LogoManagement,
		label: 'Logo Management',
		hidden: () => !includes(['admin', 'advanced'], userRole) || isFraud(),
	},
	{
		path: '/users',
		component: UsersComponent,
		label: 'Users Management',
		section: sectionKeys.users,
		hidden: isFraud,
	},
	{
		path: '/additional',
		component: UserSettingsAdditionalFunctionality,
		label: 'Additional Functionality',
		section: sectionKeys.newTransaction,
		hidden: isFraud,
	},
	{
		path: '/sola-account-settings',
		component: () => {
			return <Redirect to="/settings/sola-account-settings" />;
		},
		label: 'Gateway Settings',
		hidden: isFraud,
		isMain: true,
		tooltip: 'Global gateway account settings that function independently of the portal'
	},
];

class AccountManagement extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isSaving: false,
			expanded: false,
			isNavOpen: false,
		};
	}

	render = () => {
		return (
			<SettingsTemplate
				title={'Portal Account Settings'}
				routes={routes}
				defaultRouteUrl={'new-transaction'}
				defaultRoute={{ label: 'Keys' }}
				redirectToRoute={'account-settings/'}
				{...this.props}
			/>
		);
	};
}

AccountManagement.propTypes = {
	makePendingRequest: PropTypes.func.isRequired,
};
export default withError(withCancelable(AccountManagement));
