import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { map } from 'lodash';

import Route from '../../../routing/Route';
import { PaymentSiteList, PaymentSiteEdit } from './payment-site';

const routes = [
	{
		path: '',
		exact: true,
		component: PaymentSiteList,
	},
	{
		path: '/:id',
		component: PaymentSiteEdit,
	},
];

const NoMatch = ({ location }) => (
	<div className="l--content">
		<h3>
			Not found <code>{location.pathname}</code>
		</h3>
	</div>
);
NoMatch.propTypes = { location: PropTypes.object };

const PaymentSite = ({ match: { path }, breadcrumbRef }) => {
	return (
		<Switch>
			{map(routes, (routeData, i) =>
				routeData.hidden || routeData.disabled ? null : (
					<Route key={i} componentProps={{ breadcrumbRef }} {...routeData} path={`${path}${routeData.path}`} />
				)
			)}
			<Route component={NoMatch} />
		</Switch>
	);
};

PaymentSite.propTypes = {
	match: PropTypes.object,
	breadcrumbRef: PropTypes.object,
};

export default PaymentSite;
