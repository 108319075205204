import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { clone, every, filter, get, head, includes, map, toLower } from 'lodash';
import { DraggableColumn } from 'common/components/settings';
import { kvaasService, principalService } from 'common/services';
import handleInvalidRevision from '../utils/invalidRevision';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import BaseSettings from 'components/settings/pages/BaseSettings';
import { Notification } from 'common/components/notifications';
import { kvaasResources, mapConvenienceToCustom } from 'common/utilities';
import { Select } from 'common/components/select';
import { predefinedDates } from 'common/components/date-picker';

let principal = principalService.get();
const has3DS2 = principal.idInfo && principal.idInfo.x3DSEnabled && principal.idInfo.x3DSVersion === '2';
const predefinedDateOptions = map(predefinedDates, ({ key: name, displayValue: label }) => ({
	name,
	label,
	value: name,
}));

class UserSettingsTransactionHistory extends BaseSettings {
	constructor(props) {
		super(props);
		this.state = this.initialState;
	}

	get initialState() {
		return {
			reportType: 'transactionReport',
			transactionReport: {
				fields: this.getTransactionFields(),
				defaultValues: {
					date: '7',
					limit: '20',
					approved: false,
					includeVoidStatus: false,
					multiKeyReporting: false,
				},
				oldData: {
					isDefault: null,
					order: null,
					hide: null,
					customLabel: null,
					portalFlags: null,
					userSettings: null,
					defaultValues: null,
				},
			},
			sectionsExpanded: {
				columns: false,
			},
		};
	}

	componentDidMount = async () => {
		this.props.showLoader(true);
		await this.fetchData();
		this.props.showLoader(false);
	};
	requiredKvaasResources() {
		return kvaasService.get(
			...[
				{ ...kvaasResources.transactionReportDefaultColumns, throwError: true },
				{ ...kvaasResources.transactionReportOrder, throwError: true },
				{ ...kvaasResources.transactionReportHiddenFields, throwError: true },
				{ ...kvaasResources.transactionReportDefaultValues, throwError: true },
				{ ...kvaasResources.transactionDisplayLabels, throwError: true },
				{ ...kvaasResources.portalFlags, throwError: true },
				{ ...kvaasResources.userSettings, throwError: true },
				{ ...kvaasResources.convenienceFees, throwError: true },
			]
		);
	}

	getTransactionFields = () => {
		const initialFields = filter(this.transactionInitialFields, c => {
			let showColumn = true;
			showColumn = principal.isAdmin ? true : !c.isAdminColumn;
			if (!has3DS2 && (toLower(c.key) === 'x3dsresponsecode' || toLower(c.key) === 'x3dsconsumerinteraction')) {
				showColumn = false;
			}
			return showColumn;
		});

		return [...initialFields];
	};
	getData = async () => {
		const getKvaas = this.requiredKvaasResources();
		const [
			transactionReportDefaultColumns,
			transactionReportOrder,
			transactionReportHiddenFields,
			transactionReportDefaultValues,
			transactionDisplayLabels,
			portalFlags,
			userSettings,
			convenienceFees,
		] = await this.props.makePendingRequest(getKvaas, 'kvaas');

		return {
			transactionReportDefaultColumns,
			transactionReportOrder,
			transactionReportHiddenFields,
			transactionReportDefaultValues,
			transactionDisplayLabels,
			portalFlags,
			userSettings,
			convenienceFees,
		};
	};

	fetchData = async setToDefault => {
		if (setToDefault) {
			this.setState(this.initialState);
		}
		this.props.showLoader(true);
		try {
			const {
				transactionReportDefaultColumns,
				transactionReportOrder,
				transactionReportHiddenFields,
				transactionReportDefaultValues,
				transactionDisplayLabels,
				portalFlags,
				userSettings,
				convenienceFees,
			} = await this.getData();

			const newState = this.mapResponseToState(
				transactionReportDefaultColumns,
				transactionReportOrder,
				transactionReportHiddenFields,
				transactionDisplayLabels,
				portalFlags,
				userSettings,
				transactionReportDefaultValues
			);
			const portalFlagsData = this.getPortalFlagsData(portalFlags);

			newState.displayAuthorization = get(userSettings, 'data.displayAuthorization', false);

			const { parsedConvenienceKey, parsedOriginalKey } = mapConvenienceToCustom(
				convenienceFees,
				null,
				transactionDisplayLabels
			);

			newState.parsedConvenienceKey = parsedConvenienceKey;
			newState.parsedOriginalKey = parsedOriginalKey;
			if (!this.hasDataPortalFlags('displaySplitPayColumns', portalFlagsData)) {
				const fields = filter(newState.fields, ({ key }) => !includes(['netSale', 'processingFee'], key));
				newState.fields = fields;
			}

			if (!this.hasDataPortalFlags('displayAmexVerificationColumns', portalFlagsData)) {
				this.toggleAmexVerificationColumns(false, newState);
			}

			if (!this.hasDataPortalFlags('multipleCapture', portalFlagsData)) {
				const fields = filter(newState.fields, ({ key }) => !includes(['clearedAmount', 'clearedCount'], key));
				newState.fields = fields;
			}
			if (!this.hasDataPortalFlags('displayAmexVerificationColumns', portalFlagsData)) {
				this.toggleAmexVerificationColumns(false, newState);
			}

			this.setState({
				transactionReport: newState,
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
			}
		}
		this.props.showLoader(false);
	};
	setToDefault = async () => {
		return [
			this.mapStateToFields('transactionReport', 'order', kvaasResources.transactionReportOrder, true),
			this.mapStateToFields('transactionReport', 'hide', kvaasResources.transactionReportHiddenFields, true),
			this.mapStateToFields('transactionReport', 'isDefault', kvaasResources.transactionReportDefaultColumns, true),
			{
				newData: {
					revision: 0,
					data: { date: '7', limit: '20', approved: false, includeVoidStatus: false, multiKeyReporting: false },
				},
				oldData: this.state.transactionReport.oldData.defaultValues,
				...kvaasResources.transactionReportDefaultValues,
			},
			{
				newData: {
					revision: 0,
					data: {
						displayAuthorization: false,
					},
				},
				oldData: this.state.transactionReport.oldData.userSettings,
				...kvaasResources.userSettings,
			},
		];
	};

	hasDataUserSettings = (key, userSettingsData) => {
		return userSettingsData[key];
	};

	mapStateToReport = () => {
		return [
			this.mapStateToFields('transactionReport', 'order', kvaasResources.transactionReportOrder),
			this.mapStateToFields('transactionReport', 'hide', kvaasResources.transactionReportHiddenFields),
			this.mapStateToFields('transactionReport', 'isDefault', kvaasResources.transactionReportDefaultColumns),
			{
				newData: { revision: 0, data: this.state.transactionReport.defaultValues },
				oldData: this.state.transactionReport.oldData.defaultValues,
				...kvaasResources.transactionReportDefaultValues,
			},
			{
				newData: {
					revision: 0,
					data: {
						displayAuthorization: this.state.transactionReport.displayAuthorization,
					},
				},
				oldData: this.state.transactionReport.oldData.userSettings,
				...kvaasResources.userSettings,
			},
		];
	};

	save = async setToDefaults => {
		this.props.showLoader(true);
		let error;
		let refNum;
		try {
			const mappedState = setToDefaults ? await this.setToDefault() : this.mapStateToReport();
			const response = await this.props.makePendingRequest(kvaasService.save(...mappedState), 'save');
			refNum = head(response).refNum;

			this.props.handleBlockChange(false);
			await this.fetchData(setToDefaults);
		} catch (e) {
			this.props.showLoader(false);

			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				await this.fetchData();
			}
		}
		this.notifySuccess(error, setToDefaults, 'Transaction History', refNum);
		this.props.showLoader(false);
	};

	toggleAmexVerificationColumns = (display, newState) => {
		if (this.state.reportType !== 'transactionReport') return;
		const fields = filter(newState.fields, ({ key }) => {
			if (!display) {
				return !includes(['nameVerification', 'emailVerification', 'phoneVerification'], key);
			}
			return true;
		});

		newState.fields = fields;
	};
	handleDefaultLoadLimitChange = ({ label: limit }) => {
		const defaultValues = clone(this.state.transactionReport.defaultValues);
		defaultValues.limit = limit;
		this.setState({
			transactionReport: {
				...this.state.transactionReport,
				defaultValues: defaultValues,
			},
		});
		this.props.handleBlockChange(true);
	};
	toggleExpandCollapseSection = section => () => {
		this.setState({
			sectionsExpanded: { ...this.state.sectionsExpanded, [section]: !this.state.sectionsExpanded[section] },
		});
	};

	render = () => {
		const {
			fields,
			parsedOriginalKey,
			parsedConvenienceKey,
			defaultValues,
			displayAuthorization,
		} = this.state.transactionReport;
		const {
			sectionsExpanded: { columns },
		} = this.state;
		const selectAllValue = every(fields, i => i.hide || i.isDefault);
		const selectAllLabel = selectAllValue ? 'Unselect all' : 'Select all';

		return (
			<Fragment>
				<div className="settings--main">
					<div className="settings__header">
						<h3 className="settings__title">User Settings</h3>
						<h5>Transaction History Settings</h5>
					</div>
					<div className="card spc--bottom--lrg">
						<button
							className="card__header card__header--expandable"
							onClick={this.toggleExpandCollapseSection('columns')}
						>
							<h5>Manage Columns</h5>
							<i className={`icon icon--sml icon--chevron--${columns ? 'top' : 'down'}--primary`}></i>
						</button>
						{columns && (
							<div className="card__body">
								<div className="notes notes--primary spc--bottom--med">
									<div className="icon"></div>
									<div>
										<p className="type--p4 spc--bottom--xsml">The column name reflects the name set in the</p>
										<p className="type--p3 type--p3--medium">Account Settings {'>'} New Transaction settings.</p>
									</div>
									<button className="notes__close"></button>
								</div>

								<div className="table table--draggable table--draggable--trans-history">
									<div className="table--draggable__header">
										<div className="table--draggable__th">
											<input
												type="checkbox"
												name="selectAll"
												id="selectAll"
												checked={selectAllValue}
												className="input--check"
												onChange={this.handleChange}
											/>
											<label htmlFor="selectAll">{selectAllLabel}</label>
										</div>
										<div className="table--draggable__th"></div>
									</div>
									<DraggableColumn
										items={fields}
										parsedConvenienceKey={parsedConvenienceKey}
										parsedOriginalKey={parsedOriginalKey}
										disable={false}
										onChange={this.handleChange}
										removeCustomField={this.removeCustomField}
										onHover={this.moveColumn}
									/>
								</div>
								{this.canAddCustomFields ? (
									<button onClick={this.addCustomField} className="btn btn--sml btn--link spc--top--tny">
										Add Custom Field
									</button>
								) : null}
							</div>
						)}
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Default Date Range</span>
						</div>
						<Select
							name="defaultDateRange"
							id="defaultDateRange"
							reportType="transactionReport"
							value={this.getDate(defaultValues.date)}
							options={predefinedDateOptions}
							onChange={e => this.handleDefaultDateRangeChange(e, 'transactionReport')}
							getOptionValue={option => option.name}
						/>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Default Load Limit</span>
						</div>
						<Select
							name="defaultLoadLimit"
							id="defaultLoadLimit"
							reportType="transactionReport"
							value={this.getLimit(defaultValues.limit)}
							options={this.loadMoreOptionsWithAll}
							onChange={e => this.handleDefaultLoadLimitChange(e, 'transactionReport')}
							getOptionValue={option => option.name}
						/>
					</div>

					<div className="spc--bottom--med">
						<input
							type="checkbox"
							id="multiKeyReporting"
							name="multiKeyReporting"
							checked={defaultValues.multiKeyReporting}
							value={defaultValues.multiKeyReporting}
							onChange={this.handleActiveDefaultChange}
							className="input--check"
						/>
						<label htmlFor="multiKeyReporting">Enable Reporting Across Multiple Accounts</label>
					</div>
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							id="transactionReport.displayAuthorization"
							name="transactionReport.displayAuthorization"
							checked={displayAuthorization}
							value={displayAuthorization}
							onChange={this.handleCheckboxChange}
							className="input--check"
						/>
						<label htmlFor="transactionReport.displayAuthorization">View Authorization information on captures</label>
					</div>
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							id="approved"
							name="approved"
							checked={defaultValues.approved}
							value={defaultValues.approved}
							onChange={this.handleActiveDefaultChange}
							className="input--check"
						/>
						<label htmlFor="approved">Show Approved-Only Transactions By Default</label>
					</div>
					<div>
						<input
							type="checkbox"
							id="includeVoidStatus"
							name="includeVoidStatus"
							checked={defaultValues.includeVoidStatus}
							value={defaultValues.includeVoidStatus}
							onChange={this.handleActiveDefaultChange}
							className="input--check"
						/>
						<label htmlFor="includeVoidStatus">Show Void Status When Exported</label>
					</div>
				</div>

				<div className="settings__footer">{this.renderSaveButton()}</div>

				<Notification ref={this.notification} />
			</Fragment>
		);
	};
}

UserSettingsTransactionHistory.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	showLoader: PropTypes.func,
	history: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(UserSettingsTransactionHistory))), handleInvalidRevision);
