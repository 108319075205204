import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import ModalComponent from 'common/components/modal/modal';
import { principalService } from 'common/services';

class OldPortal extends Component {
	state = {
		isShareFeedbackOpen: false,
		principal: principalService.get(),
	};

	toggleShareFeedback = () => {
		this.setState({ isShareFeedbackOpen: !this.state.isShareFeedbackOpen });
	};

	render() {
		const { optInForNewPortal } = this.props;
		const { isShareFeedbackOpen, principal } = this.state;
		const mid = get(principal, 'idInfo.xMerchantID');
		const email = get(principal, 'email');
		return (
			<Fragment>
				<div className="">
					Thanks for joining our pilot program! We hope that you’re enjoying our latest features and improvements. Your
					feedback is greatly appreciated—please{' '}
					<a className="btn btn--link" href="#" onClick={this.toggleShareFeedback}>
						click here
					</a>{' '}
					to submit it.To go back to previous version{' '}
					<a className="btn btn--link" href="#" onClick={optInForNewPortal}>
						click here.
					</a>
				</div>
				<ModalComponent
					isOpen={isShareFeedbackOpen}
					onClose={this.toggleShareFeedback}
					className="modal__content modal--beta-feedback"
				>
					{mid && email && (
						<div className="modal__body modal__body--beta-feedback">
							<iframe
								height="1590"
								title="Embedded Wufoo Form"
								frameBorder="0"
								scrolling="no"
								style={{ width: '100%', border: 'none' }}
								src={`https://sola.wufoo.com/forms/z1kj5p61k4x3a9/?Field118=${encodeURIComponent(
									mid
								)}&Field119=${encodeURIComponent(email)}`}
							/>
						</div>
					)}
				</ModalComponent>
			</Fragment>
		);
	}
}

OldPortal.propTypes = {
	optInForNewPortal: PropTypes.func,
};

export default OldPortal;
